import React, { useState, useEffect } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function RondasConfig() {
  const classes = useStyles();
  const [nombre, setNombre] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [ndias, setNdias] = React.useState("");
  const [tipo, setTipo] = React.useState("2");
  const [diaInicial, setDiaInicial] = React.useState("");
  const [diaFinal, setDiaFinal] = React.useState("");
  const [dataTipos, setDataTipos] = React.useState([{}]);
  const [dataZonas, setDataZonas] = React.useState([{}]);
  const [dataMaqs, setDataMaqs] = React.useState([{}]);
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const [recurrencia, setRecurrencia] = React.useState(null);
  const fetchData = async () => {
    const resultTypes = await axios("https://epstool.com/api/rondascooper/alltypes");
    // const resultTypes = await axios(
    //   "http://localhost:5005/api/rondascooper/alltypes"
    // );
    // const resultZones = await axios(
    //   "http://localhost:5005/api/rondascooper/allzones"
    // );
    const resultZones = await axios("https://epstool.com/api/rondascooper/allzones");

    setDataTipos(resultTypes.data);
    setDataZonas(resultZones.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveConfig = async () => {
    const configToSave = {
      nombre: nombre,
      descripcion: descripcion,
      frecuencia: {
        categoria: recurrencia,
        diaInicio: diaInicial,
        diaFinal: diaFinal,
        nDias: ndias,
      },
      tipo: tipo,
      elementos: multipleSelect,
    };
    console.log(configToSave);

    await axios
      // .post("http://localhost:5005/api/rondascooper/nuevarondaconfig", configToSave)
      .post("https://epstool.com/api/rondascooper/nuevarondaconfig", configToSave)
      .then((res) => {
        console.log(res);
        setNombre("");
        setDescripcion("");
        setNdias("");
        setTipo("2");
        setDiaInicial("");
        setDiaFinal("");
        setMultipleSelect([]);
        setRecurrencia(null);
        // actualizardia();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const actualizardia = async () => {
    await axios("https://epstool.com/api/rondascooper/actualizardiaconfig");
    // await axios("http://localhost:5005/api/rondascooper/actualizardiaconfig");
  };

  const semana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const getMaquinas = async () => {
    // const resultMaqs = await axios("http://localhost:5005/api/rondascooper/namesmaq");
    const resultMaqs = await axios("https://epstool.com/api/rondascooper/namesmaq");
    setDataMaqs(resultMaqs.data);
  };

  let selectsOne = (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
        Selecione
      </InputLabel>
      <Select
        multiple
        value={multipleSelect}
        onChange={(e) => setMultipleSelect(e.target.value)}
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
        inputProps={{
          name: "multipleSelect",
          id: "multiple-select",
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          Selecione
        </MenuItem>
        {dataMaqs.map((maqs) => (
          <MenuItem
            key={maqs._id}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelectedMultiple,
            }}
            value={maqs._id}
          >
            {maqs.codMaquina}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  let selectsTwo = (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
        Selecione
      </InputLabel>
      <Select
        multiple
        value={multipleSelect}
        onChange={(e) => setMultipleSelect(e.target.value)}
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
        inputProps={{
          name: "multipleSelect",
          id: "multiple-select",
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          Selecione
        </MenuItem>
        {dataTipos.map((tipos) => (
          <MenuItem
            key={tipos._id}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelectedMultiple,
            }}
            value={tipos._id}
          >
            {tipos.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  let selectsThree = (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
        Selecione
      </InputLabel>
      <Select
        multiple
        value={multipleSelect}
        onChange={(e) => setMultipleSelect(e.target.value)}
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
        inputProps={{
          name: "multipleSelect",
          id: "multiple-select",
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          Selecione
        </MenuItem>
        {dataZonas.map((zona) => (
          <MenuItem
            key={zona._id}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelectedMultiple,
            }}
            value={zona._id}
          >
            {zona.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  let selects;
  switch (tipo) {
    case "1":
      if (dataMaqs) {
        getMaquinas();
      }
      selects = selectsOne;
      break;
    case "2":
      selects = selectsTwo;
      break;
    case "3":
      selects = selectsThree;
      break;
    default:
      break;
  }

  let diaIni = (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        Dia Inicial*
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={diaInicial}
        onChange={(e) => setDiaInicial(e.target.value)}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          Dia Inicial
        </MenuItem>
        {semana.map((element, index) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={index}
          >
            {element}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  let diaFin = (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        Dia Final*
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={diaFinal}
        onChange={(e) => setDiaFinal(e.target.value)}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
        >
          Dia Final
        </MenuItem>
        {semana.map((element, index) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={index}
          >
            {element}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  let CNdias = (
    <GridContainer>
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>Días:</FormLabel>
      </GridItem>
      <GridItem xs={12} sm={6}>
        <CustomInput
          labelText="Ej: 3"
          id="dias"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (event) => {
              setNdias(event.target.value);
            },
            type: "number",
            value: ndias,
          }}
        />
      </GridItem>
    </GridContainer>
  );

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success" text>
          <CardText color="success">
            <h4 className={classes.cardTitle}>Configuración Ronda</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          {/* Nombre de la configuracion */}
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Nombre de la ronda:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <CustomInput
                labelText="Ej: Ronda diaria diurna"
                id="nombreRonda"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setNombre(event.target.value);
                  },
                  type: "text",
                  value: nombre,
                }}
              />
            </GridItem>
            <GridItem xs={12} xs={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Tipo de Ronda*
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Tipo de Ronda
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="1"
                  >
                    Equipos
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="2"
                  >
                    Tipos
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="3"
                  >
                    Zonas
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Elementos:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={10}>
              {selects}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <FormLabel className={classes.labelCustomHorizontal}>
                Recurrencia:
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={recurrencia === "a"}
                      onChange={(e) => setRecurrencia(e.target.value)}
                      value="a"
                      name="radio button demo"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Diario"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={recurrencia === "b"}
                      onChange={(e) => setRecurrencia(e.target.value)}
                      value="b"
                      name="radio button demo"
                      aria-label="B"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Semanal"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={recurrencia === "c"}
                      onChange={(e) => setRecurrencia(e.target.value)}
                      value="c"
                      name="radio button demo"
                      aria-label="C"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="N dias"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={recurrencia === "d"}
                      onChange={(e) => setRecurrencia(e.target.value)}
                      value="d"
                      name="radio button demo"
                      aria-label="D"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Rango"
                />
              </div>
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={recurrencia === "e"}
                      onChange={(e) => setRecurrencia(e.target.value)}
                      value="e"
                      name="radio button demo"
                      aria-label="E"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label="Programable"
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={4}>
              {recurrencia === "e" ? null : (
                <FormLabel className={classes.labelCustomHorizontal}>
                  Detalles:
                </FormLabel>
              )}
              {recurrencia === "a" ? diaIni : null}
              {recurrencia === "b" || recurrencia === "d" ? diaIni : null}
              {recurrencia === "d" ? diaFin : null}
              {recurrencia === "c" ? CNdias : null}
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Descripción:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  multiline
                  // labelText="Ej: Ronda diaria diurna"
                  id="descripcion"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: (event) => {
                      setDescripcion(event.target.value);
                    },
                    type: "text",
                    value: descripcion,
                  }}
                />
              </GridItem>
            </GridContainer>
            <br />
            <br />
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Button fullWidth color="success" onClick={handleSaveConfig}>
                  Guardar Configuración
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        </CardBody>
      </Card>
    </GridContainer>
  );
}
