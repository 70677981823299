import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import Place from "@material-ui/icons/Place";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import Danger from "components/Typography/Danger";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Snackbar from "components/Snackbar/Snackbar.js";
import Close from "@material-ui/icons/Close";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function IngresarInforme(props) {
  const classes = useStyles();
  const decoded = jwt_decode(localStorage.jwtToken);

  const idRonda = props.match.params.idronda;
  const idMaq = props.match.params._id;
  const idForm = props.match.params.idform;
  const idNfc = props.match.params.idnfc;
  const [comentario, setComentario] = React.useState("");
  const [equipo, setEquipo] = React.useState();
  const [variables, setVariables] = React.useState();
  const [variablesNoche, setVariablesNoche] = React.useState();
  const [tc, setTC] = React.useState(false);
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [txtSaltar, setTxtSaltar] = useState("");
  const [errSaltar, setErrSaltar] = useState("");
  const [saltarModal, setSaltarModal] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [savingModal, setSavingModal] = React.useState(false);
  const [fecha, setFecha] = useState();

  const [form, setForm] = React.useState([
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
  ]);
  const [errors, setErrors] = React.useState([
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
  ]);

  const [formNoche, setFormNoche] = React.useState([
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
  ]);
  const [errorsNoche, setErrorsNoche] = React.useState([
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
  ]);

  // CARGA DEL EQUIPO
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/maquina/${idMaq}`

      `https://epstool.com/api/rondascooper/maquina/${idMaq}`
    );
    setEquipo(result.data);
    setVariables(result.data.tipo.variables);
    setVariablesNoche(result.data.tipo.variables);
    setForm(
      result.data.tipo.variables.map((element, index) => ({
        id: index,
        nombre: element.nombre,
        tipo: element.tipo,
        graficar: element.graficar,
        valor: "",
        comentario: "",
      }))
    );
    setFormNoche(
      result.data.tipo.variables.map((element, index) => ({
        id: index,
        nombre: element.nombre,
        tipo: element.tipo,
        graficar: element.graficar,
        valor: "",
        comentario: "",
      }))
    );
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleFrom = (e) => {
    setFecha(e.unix());
    console.log(e.unix());
  };

  const handleCustomInput = async (event, id, tipo) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (tipo) {
      case 0:
        form[id].valor = txt;
        setForm([...form]);
        break;
      case 1:
        form[id].comentario = txt;
        setForm([...form]);
        break;
      default:
        break;
    }
  };

  const handleCustomInputNoche = async (event, id, tipo) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (tipo) {
      case 0:
        formNoche[id].valor = txt;
        setFormNoche([...formNoche]);
        break;
      case 1:
        formNoche[id].comentario = txt;
        setFormNoche([...formNoche]);
        break;
      default:
        break;
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    setSavingModal(true);

    const informeToSave = {
      fecha: fecha,
      dataDia: form,
      dataNoche: formNoche,
      maquina: idMaq,
      idmaq: equipo._id,
      idUser: decoded.id,
      userName: decoded.name,
    };

    await axios
      // .post("http://localhost:5005/api/rondascooper/informesp", informeToSave)
      .post("https://epstool.com/api/rondascooper/informesp", informeToSave)
      
      .then((res) => {
        setComentario("");
        setForm(
          variables.map((element, index) => ({
            id: index,
            nombre: element.nombre,
            tipo: element.tipo,
            graficar: element.graficar,
            valor: "",
            comentario: "",
          }))
        );
        setFormNoche(
          variables.map((element, index) => ({
            id: index,
            nombre: element.nombre,
            tipo: element.tipo,
            graficar: element.graficar,
            valor: "",
            comentario: "",
          }))
        );
        setSavingModal(false);
        setSuccessModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let formulario;
  variables
    ? (formulario = variables.map((element, index) => (
        <div key={element._id}>
          {element.tipo === 1 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  {element.nombre}*:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  multiline
                  labelText="Ej: Temperatura"
                  id={element._id.toString()}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleCustomInput(e, index, 0),
                    type: "text",
                    value: form[index].valor,
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : null}
          {element.tipo === 2 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  <b>{element.nombre}*:</b>
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  multiline
                  labelText="Digite valor solicitado"
                  id={element._id.toString()}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleCustomInput(e, index, 0),
                    //   onChange: (e) => handleCustomInput(e, index, 1),
                    type: "number",
                    value: form[index].valor,
                    //   value: form[index].valor,
                  }}
                />
                <Danger>
                  {errors[index].valor === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors[index].valor}
                    </div>
                  )}
                </Danger>
              </GridItem>
              {element.minimo ? (
                <GridItem xs={6} sm={2}>
                  <p className={classes.labelCustomHorizontal}>
                    Min: {element.minimo}
                  </p>
                </GridItem>
              ) : null}
              {element.maximo ? (
                <GridItem xs={6} sm={2}>
                  <p className={classes.labelCustomHorizontal}>
                    Max: {element.maximo}
                  </p>
                </GridItem>
              ) : null}
              {element.minimo &&
              form[index].valor.length >= 1 &&
              (form[index].valor < element.minimo ||
                form[index].valor > element.maximo) ? (
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelCustomHorizontal}>
                    <b>Comentario:</b>
                  </FormLabel>
                </GridItem>
              ) : null}
              {element.minimo &&
              form[index].valor.length >= 1 &&
              (form[index].valor < element.minimo ||
                form[index].valor > element.maximo) ? (
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    multiline
                    labelText="Detalle el valor ingresado"
                    id={element._id.toString()}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => handleCustomInput(e, index, 1),
                      type: "text",
                      value: form[index].comentario,
                    }}
                  />
                </GridItem>
              ) : null}
              <GridItem xs={12}>
                <Divider />
              </GridItem>
            </GridContainer>
          ) : null}
          {element.tipo === 3 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    classes.labelCustomHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  <b>{element.nombre}*:</b>
                </FormLabel>
              </GridItem>
              <GridItem xs={2} sm={1} md={1} lg={1}></GridItem>
              <GridItem xs={10} sm={7}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          element.boolAfirmativo
                            ? form[index].valor === element.boolAfirmativo
                            : form[index].valor === "SI"
                        }
                        onChange={(e) => handleCustomInput(e, index, 0)}
                        value={
                          element.boolAfirmativo ? element.boolAfirmativo : "SI"
                        }
                        name="radio button demo"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={
                      element.boolAfirmativo ? element.boolAfirmativo : "SI"
                    }
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          element.boolNegativo
                            ? form[index].valor === element.boolNegativo
                            : form[index].valor === "NO"
                        }
                        onChange={(e) => handleCustomInput(e, index, 0)}
                        value={
                          element.boolNegativo ? element.boolNegativo : "NO"
                        }
                        name="radio button demo"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={element.boolNegativo ? element.boolNegativo : "NO"}
                  />
                </div>
                <Danger>
                  {errors[index].valor === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors[index].valor}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
          ) : null}
        </div>
      )))
    : (formulario = "");

  let formularioNoche;
  variablesNoche
    ? (formularioNoche = variablesNoche.map((element, index) => (
        <div key={element._id}>
          {element.tipo === 1 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  {element.nombre}*:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  multiline
                  labelText="Ej: Temperatura"
                  id={element._id.toString()}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleCustomInputNoche(e, index, 0),
                    type: "text",
                    value: formNoche[index].valor,
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : null}
          {element.tipo === 2 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  <b>{element.nombre}*:</b>
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  multiline
                  labelText="Digite valor solicitado"
                  id={element._id.toString()}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleCustomInputNoche(e, index, 0),
                    //   onChange: (e) => handleCustomInput(e, index, 1),
                    type: "number",
                    value: formNoche[index].valor,
                    //   value: form[index].valor,
                  }}
                />
                <Danger>
                  {errors[index].valor === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors[index].valor}
                    </div>
                  )}
                </Danger>
              </GridItem>
              {element.minimo ? (
                <GridItem xs={6} sm={2}>
                  <p className={classes.labelCustomHorizontal}>
                    Min: {element.minimo}
                  </p>
                </GridItem>
              ) : null}
              {element.maximo ? (
                <GridItem xs={6} sm={2}>
                  <p className={classes.labelCustomHorizontal}>
                    Max: {element.maximo}
                  </p>
                </GridItem>
              ) : null}
              {element.minimo &&
              form[index].valor.length >= 1 &&
              (form[index].valor < element.minimo ||
                form[index].valor > element.maximo) ? (
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelCustomHorizontal}>
                    <b>Comentario:</b>
                  </FormLabel>
                </GridItem>
              ) : null}
              {element.minimo &&
              form[index].valor.length >= 1 &&
              (form[index].valor < element.minimo ||
                form[index].valor > element.maximo) ? (
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    multiline
                    labelText="Detalle el valor ingresado"
                    id={element._id.toString()}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => handleCustomInputNoche(e, index, 1),
                      type: "text",
                      value: form[index].comentario,
                    }}
                  />
                </GridItem>
              ) : null}
              <GridItem xs={12}>
                <Divider />
              </GridItem>
            </GridContainer>
          ) : null}
          {element.tipo === 3 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    classes.labelCustomHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  <b>{element.nombre}*:</b>
                </FormLabel>
              </GridItem>
              <GridItem xs={2} sm={1} md={1} lg={1}></GridItem>
              <GridItem xs={10} sm={7}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          element.boolAfirmativo
                            ? formNoche[index].valor === element.boolAfirmativo
                            : formNoche[index].valor === "SI"
                        }
                        onChange={(e) => handleCustomInputNoche(e, index, 0)}
                        value={
                          element.boolAfirmativo ? element.boolAfirmativo : "SI"
                        }
                        name="radio button demo"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={
                      element.boolAfirmativo ? element.boolAfirmativo : "SI"
                    }
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          element.boolNegativo
                            ? formNoche[index].valor === element.boolNegativo
                            : formNoche[index].valor === "NO"
                        }
                        onChange={(e) => handleCustomInputNoche(e, index, 0)}
                        value={
                          element.boolNegativo ? element.boolNegativo : "NO"
                        }
                        name="radio button demo"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={element.boolNegativo ? element.boolNegativo : "NO"}
                  />
                </div>
                <Danger>
                  {errors[index].valor === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors[index].valor}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
          ) : null}
        </div>
      )))
    : (formularioNoche = "");

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Informe del equipo {equipo ? equipo.codMaquina : ""}
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Seleccione rango de fecha
                </FormLabel>
                <GridItem xs={6}>
                  <FormControl fullWidth>
                    <Datetime
                      onChange={handleFrom}
                      timeFormat={false}
                      inputProps={{ placeholder: "Desde" }}
                    />
                  </FormControl>
                </GridItem>
                <br />
              </GridItem>
            </GridContainer>
            <h4>Informe dia</h4>
            {formulario}
            <h4>Informe Noche</h4>
            {formularioNoche}
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Comentarios:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  multiline
                  labelText=""
                  id="coment"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: (e) => setComentario(e.target.value),
                    type: "text",
                    value: comentario,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <Button fullWidth color="success" onClick={handleSave}>
                Guardar Informe
              </Button>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      {/* Modal Success */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={successModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSuccessModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setSuccessModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Informe almacenado con éxito</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>Escoja una acción a realizar:</p>
          <Button
            onClick={() => {
              props.history.push(`../../../../../admin/qrscanner`);
            }}
            block
            color="success"
          >
            Abrir Scanner
          </Button>
          <Button
            onClick={() => {
              props.history.push(
                `../../../../../admin/qrcode/equipo/${equipo.uuid}`
              );
            }}
            block
            color="danger"
          >
            Volver al equipo {equipo ? equipo.codMaquina : ""}
          </Button>
        </DialogContent>
      </Dialog>
      {/* Enviando Informe */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={savingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSavingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>Guardando informe, espere un momento...</p>
        </DialogContent>
      </Dialog>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}
