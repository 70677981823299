import React, { useState, useEffect } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(styles);

export default function Informe(props) {
  const classes = useStyles();
  const idInforme = props.match.params._id;
  const [informe, setInforme] = React.useState({
    codigo: "",
    comentario: "",
    maquina: "",
    user: "",
    ronda: [],
    informacion: [],
  });

  const fetchData = async () => {
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/informe/${idInforme}`
      `https://epstool.com/api/rondascooper/informe/${idInforme}`
    );
    // console.log(result.data);

    setInforme(result.data);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {informe ? `Informe ${informe.codigo}` : ""}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <p>
                    {informe ? `Maquina: ${informe.maquina.codMaquina}` : ""}
                  </p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>{informe ? `Realizado por: ${informe.user.name}` : ""}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {informe
                      ? `Fecha: ${new Date(
                          informe.fecha * 1000
                        ).toLocaleString()}`
                      : ""}
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {informe
                  ? informe.ronda.map((ronda) => (
                      <GridItem key={ronda._id} xs={12} sm={4}>
                        <p>Ronda: {ronda.nombre}</p>
                      </GridItem>
                    ))
                  : ""}
                {/* <GridItem xs={12} sm={4}>
                  <p>{equipo ? `Ubicación: ${equipo.ubicacion.nombre}` : ""}</p>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <p>
                    {equipo
                      ? `Código: ${equipo.codEspecial}`
                      : "No registra código"}
                  </p>
                </GridItem> */}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {informe.comentario ? (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Place />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Comentario del reporte
                </h4>
              </CardHeader>
              <CardBody>
                <p>{informe.comentario}</p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Detalles</h4>
            </CardHeader>
            <CardBody>
              {informe
                ? informe.informacion.map((element) => {
                    console.log(element);

                    return (
                      <GridItem xs={12} sm={4} key={element._id}>
                        <p>
                          {element.nombre}: {element.valor}
                        </p>
                      </GridItem>
                    );
                  })
                : ""}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
