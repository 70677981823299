export const arrOfInformes = [
  {
    id: 1,
    title: "Mecanico",
    sections: [
      {
        id: 1,
        uuid: "HVACProductionRoom",
        cod: "hvac",
        label: "Sistema HVAC Cuarto Producción (Manejadoras de Aire)",
        type: "radio",
      },
      {
        id: 2,
        uuid: "AirExtractionSystems",
        cod: "extraccion",
        label: "Sistemas de Extracción de Aire (Abanicos)",
        type: "radio",
      },
      {
        id: 3,
        uuid: "CHWSystem",
        cod: "chw",
        label: "Sistema CHW  (Chillers y Bombas)",
        type: "radio",
      },
      {
        id: 4,
        width: 6,
        cod: "appro1",
        label: "Appro Evap (•F)",
        type: "text",
      },
      {
        id: 5,
        width: 6,
        cod: "appro2",
        label: "Appro Evap (•F)",
        type: "text",
      },
      {
        id: 6,
        uuid: "CWSystem",
        cod: "cw",
        label: "Sistema CW  (Torres, Bombas y Lakos)",
        type: "radio",
      },
      {
        id: 7,
        uuid: "ProcessWaterSystem",
        cod: "agua",
        label: "Sistema de Agua de Proceso (Bombas / Intercambiadores)",
        type: "radio",
      },
      {
        id: 8,
        uuid: "ChemicalTreatmentSystem",
        cod: "tratamiento",
        label:
          "Sistema de tratamiento químico (Torres,Agua Helada,Sistema Cerrado, Calderas)",
        type: "radio",
      },
      {
        id: 9,
        uuid: "CompressedAirSystem",
        cod: "aire",
        label: "Sistema de Aire Comprimido (Compresores, Secadores)",
        type: "radio",
      },
      {
        id: 10,
        uuid: "WaterSofteners",
        cod: "suavizadores",
        label: "Suavizadores de Agua",
        type: "radio",
      },
      {
        id: 11,
        width: 12,
        cod: "ppm",
        label: "Dureza(ppm)",
        type: "text",
      },

      {
        id: 12,
        uuid: "UltrapureWaterSystems",
        cod: "aguaUltraPura",
        label: "Sistemas de Agua Ultrapura (USP Water)",
        type: "radio",
      },
      {
        id: 13,
        width: 4,
        cod: "ups1",
        label: "Usp-01 (µS/cm)",
        type: "text",
      },
      {
        id: 14,
        width: 4,
        cod: "ups2",
        label: "Usp-02 (µS/cm)",
        type: "text",
      },
      {
        id: 15,
        width: 4,
        cod: "ups3",
        label: "Usp-03 (µS/cm)",
        type: "text",
      },
      {
        id: 16,
        uuid: "DrinkingWaterSystem",
        cod: "aguaPotable",
        label: "Sistema de Agua Potable (Bombas)",
        type: "radio",
      },
      {
        id: 17,
        width: 12,
        cod: "cloro",
        label: "Cloro (ppm)",
        type: "text",
      },
      {
        id: 18,
        uuid: "FireProtectionSystems",
        cod: "contraIncendio",
        label: "Sistemas Contra Incendio",
        type: "radio",
      },
      {
        id: 19,
        width: 12,
        cod: "dieselComplementary",
        label: "Diesel (L)",
        type: "text",
      },
      {
        id: 20,
        uuid: "SteamSystem",
        cod: "vapor",
        label: "Sistema de Vapor (Calderas)",
        type: "radio",
      },
      {
        id: 21,
        uuid: "GasSystems",
        cod: "gas",
        label: "Sistemas de Gas (Nitrógeno)",
        type: "radio",
      },
      {
        id: 22,
        width: 12,
        cod: "nivel",
        label: "Nivel (L)",
        type: "text",
      },
      {
        id: 23,
        uuid: "VacuumSystem",
        cod: "vacio",
        label: "Sistema de Vacío (Donaldson)",
        type: "radio",
      },
      {
        id: 24,
        uuid: "ResinSystem",
        cod: "resina",
        label: "Sistema de Resina (Bombas de Vacío y Silos)",
        type: "radio",
      },
      {
        id: 25,
        width: 3,
        cod: "silo1",
        label: "Silo 01 (ton)",
        type: "text",
      },
      {
        id: 26,
        width: 3,
        cod: "silo2",
        label: "Silo 02 (ton)",
        type: "text",
      },
      {
        id: 27,
        width: 3,
        cod: "silo3",
        label: "Silo 03 (ton)",
        type: "text",
      },
      {
        id: 28,
        width: 3,
        cod: "silo4",
        label: "Silo 04 (ton)",
        type: "text",
      },
      {
        id: 29,
        width: 3,
        cod: "silo5",
        label: "Silo 05 (ton)",
        type: "text",
      },
      {
        id: 30,
        width: 3,
        cod: "silo6",
        label: "Silo 06 (ton)",
        type: "text",
      },
      {
        id: 31,
        width: 3,
        cod: "silo7",
        label: "Silo 07 (ton)",
        type: "text",
      },
      {
        id: 32,
        width: 3,
        cod: "silo8",
        label: "Silo 08 (ton)",
        type: "text",
      },
      {
        id: 33,
        uuid: "HVACOffices",
        cod: "hvacOficinas",
        label: "Sistema HVAC Oficinas (Manejadoras de Aire, VAV, BC's)",
        type: "radio",
      },
      {
        id: 34,
        uuid: "WalkInCooler",
        cod: "cooler",
        label: "Walk in Cooler",
        type: "radio",
      },
    ],
  },
  {
    id: 2,
    title: "Electrico",
    sections: [
      {
        id: 1,
        uuid: "PowerSupply",
        cod: "ice",
        label: "Suministro Eléctrico (ICE)",
        type: "radio",
      },
      {
        id: 2,
        uuid: "Switchgear",
        cod: "seccionadora",
        label: "Seccionadora",
        type: "radio",
      },
      {
        id: 3,
        uuid: "GeneralTransformers",
        cod: "transformadores",
        label: "Transformadores Generales",
        type: "radio",
      },
      {
        id: 4,
        uuid: "ElectricalSubstation",
        cod: "subestacion",
        label: "Subestación Eléctrica",
        type: "radio",
      },
      {
        id: 5,
        uuid: "EmergencyGenerators",
        cod: "generadores",
        label: "Generadores de Emergencia",
        type: "radio",
      },
      {
        id: 6,
        uuid: "ElectricalPanels",
        cod: "tableros",
        label: "Tableros Eléctricos",
        type: "radio",
      },
      {
        id: 7,
        uuid: "UPS",
        cod: "ups",
        label: "Sistema de UPS",
        type: "radio",
      },
      {
        id: 8,
        uuid: "LightingSystem",
        cod: "iluminacion",
        label: "Sistema de Iluminación",
        type: "radio",
      },
      {
        id: 9,
        uuid: "FireSuppressionSystem",
        cod: "supresion",
        label: "Sistema de supresión de Incendio (Ecaro)",
        type: "radio",
      },
      {
        id: 10,
        uuid: "FireNotificationSystem",
        cod: "notificacion",
        label: "Sistema de Notificación de Incendio",
        type: "radio",
      },
      {
        id: 11,
        uuid: "DieselSystem",
        cod: "diesel",
        label: "Sistema de Diésel (Tanques y Bombas)",
        type: "radio",
      },
    ],
  },
  {
    id: 3,
    title: "BMS",
    sections: [
      {
        id: 1,
        uuid: "bms",
        cod: "bms",
        label: "BMS",
        type: "radio",
      },
    ],
  },
  {
    id: 4,
    title: "Edificio",
    sections: [
      {
        id: 1,
        uuid: "CleanRooms",
        cod: "cuartosLimpios",
        label: "Cuartos Limpios",
        type: "radio",
      },
      {
        id: 2,
        uuid: "BuildingCleaningAndOrnamentation",
        cod: "aseo",
        label: "Aseo y Ornato de Edificio (Áreas de Trabajo)",
        type: "radio",
      },
      {
        id: 3,
        uuid: "RegularBuildingMaintenanceTasks",
        cod: "tareasRegulares",
        label: "Tareas regulares en Mantenimiento de edificio",
        type: "radio",
      },
      {
        id: 4,
        uuid: "PestControlSystem",
        cod: "plagas",
        label: "Sistema Control de Plagas",
        type: "radio",
      },
    ],
  },
];

export const turnos = [
  { nombre: "T1 - Diurno", value: "t1" },
  { nombre: "T2 - Diurno", value: "t2" },
  { nombre: "T3 - Nocturno", value: "t3" },
  { nombre: "T4 - Nocturno", value: "t4" },
];

export const defaultValues = {
  estado: "",
  turno: "",
  pendientes: "",
  hvac: "",
  extraccion: "",
  chw: "",
  appro1: "",
  appro2: "",
  cw: "",
  agua: "",
  tratamiento: "",
  aire: "",
  suavizadores: "",
  ppm: "",
  aguaUltraPura: "",
  ups1: "",
  ups2: "",
  ups3: "",
  aguaPotable: "",
  cloro: "",
  contraIncendio: "",
  dieselComplementary: "",
  vapor: "",
  gas: "",
  nivel: "",
  vacio: "",
  resina: "",
  silo1: "",
  silo2: "",
  silo3: "",
  silo4: "",
  silo5: "",
  silo6: "",
  silo7: "",
  silo8: "",
  hvacOficinas: "",
  cooler: "",
  ice: "",
  seccionadora: "",
  transformadores: "",
  subestacion: "",
  generadores: "",
  tableros: "",
  ups: "",
  iluminacion: "",
  supresion: "",
  notificacion: "",
  diesel: "",
  bms: "",
  cuartosLimpios: "",
  aseo: "",
  tareasRegulares: "",
  plagas: "",
};

export const aditionalInputs = [
  {
    name: "appro1",
    sm: 6,
    title: "Appro Evap (•F)",
  },
  {
    name: "appro2",
    sm: 6,
    title: "Appro Evap (•F)",
  },
  {
    name: "ppm",
    sm: 12,
    title: "Dureza(ppm)",
  },
  {
    name: "ups1",
    sm: 4,
    title: "Usp-01 (µS/cm)",
  },
  {
    name: "ups2",
    sm: 4,
    title: "Usp-02 (µS/cm)",
  },
  {
    name: "ups3",
    sm: 4,
    title: "Usp-03 (µS/cm)",
  },
  {
    name: "cloro",
    sm: 12,
    title: "Cloro (ppm)",
  },
  {
    name: "diesel",
    sm: 12,
    title: "Diesel (L)",
  },
  {
    name: "nivel",
    sm: 12,
    title: "Nivel(L)",
  },
  {
    name: "silo1",
    sm: 3,
    title: "Silo 01 (ton)",
  },
  {
    name: "silo2",
    sm: 3,
    title: "Silo 02 (ton)",
  },
  {
    name: "silo3",
    sm: 3,
    title: "Silo 03 (ton)",
  },
  {
    name: "silo4",
    sm: 3,
    title: "Silo 04 (ton)",
  },
  {
    name: "silo5",
    sm: 3,
    title: "Silo 05 (ton)",
  },
  {
    name: "silo6",
    sm: 3,
    title: "Silo 06 (ton)",
  },
  {
    name: "silo7",
    sm: 3,
    title: "Silo 07 (ton)",
  },
  {
    name: "silo8",
    sm: 3,
    title: "Silo 08 (ton)",
  },
];
