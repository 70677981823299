import React, { useState, useEffect } from "react";
import axios from "axios";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import usePassdown from "../hooks/usePassdown";
import Accordion from "components/Accordion/Accordion";
import DetailTextfield from "./components/DetailTextfield";
import { aditionalInputs } from "_mock/_passdown";

export default function Detail(props) {
  const { orderData } = usePassdown();
  const { id } = props.match.params;
  const [informeDetail, setInformeDetail] = useState([]);

  const initData = async () => {
    // await axios.get(`http://localhost:5050/api/passdown/${id}`).then((res) => {
    await axios.get(`https://epstool.com/api/passdown/${id}`).then((res) => {
      setInformeDetail(res.data);
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const dataToShow = orderData(informeDetail);
  const dataOfObject = dataToShow?.slice(1, -1);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <MenuBookIcon />
            </CardIcon>
            <h4 style={{ color: "#000" }}>Detalle de infome (General)</h4>
          </CardHeader>
          <CardBody>
            <Grid
              container
              spacing={4}
              alignItems="center"
              style={{ margin: 4 }}
            >
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Encargado</Typography>
                <Typography>{informeDetail?.encargado}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Fecha</Typography>
                <Typography>
                  {new Date(informeDetail?.fecha).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2">Turno</Typography>
                <Typography style={{ textTransform: "uppercase" }}>
                  {informeDetail?.turno}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2">Pendientes</Typography>
                <Typography>{informeDetail?.pendientes}</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Typography variant="subtitle2">PDF</Typography>
                  <Button
                    style={{
                      backgroundColor: "#4caf50",
                      color: "#fff",
                    }}
                    size="small"
                    variant="contained"
                  >
                    <a
                      style={{ textDecoration: "none", color: "#fff" }}
                      href={
                        `https://epstool.com/api/passdown/pdf/${id}`
                        // `http://localhost:5050/api/passdown/pdf/${id}`
                      }
                      target="_blank"
                      download
                    >
                      Descargar
                    </a>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <MenuBookIcon />
            </CardIcon>
            <h4 style={{ color: "#000" }}>Detalle de infome (Formulario)</h4>
          </CardHeader>
          <CardBody>
            <Grid
              container
              spacing={4}
              alignItems="center"
              style={{ margin: { sm: 4 } }}
            >
              {dataOfObject?.map((el) => (
                <React.Fragment key={el.titulo}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{el.titulo}</Typography>
                  </Grid>
                  {el.section?.map((item, i) => (
                    <React.Fragment key={i}>
                      <Grid item xs={12} sm={6}>
                        <FormLabel>{item.label}</FormLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <FormControl>
                          <RadioGroup row value={item.isActive ?? ""}>
                            <FormControlLabel
                              value="normal"
                              control={<Radio />}
                              label="Normal"
                            />
                            <FormControlLabel
                              value="evento"
                              control={<Radio />}
                              label="Evento"
                            />
                          </RadioGroup>
                        </FormControl>

                        {item.files?.length > 0 && (
                          <Box width={120} marginLeft={5}>
                            <Accordion
                              collapses={[
                                {
                                  title: "imagenes",
                                  content: (
                                    <List>
                                      {item.files?.map((image) => (
                                        <ListItem key={image.url}>
                                          <ListItemText
                                            primary={
                                              <a
                                                href={image.url}
                                                target="_blank"
                                              >
                                                {image.fileName}
                                              </a>
                                            }
                                          />
                                        </ListItem>
                                      ))}
                                    </List>
                                  ),
                                },
                              ]}
                            />
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <DetailTextfield
                          fullwidth
                          title="Descripción"
                          value={item.description}
                        />
                      </Grid>

                      {aditionalInputs?.map((inp, i) => {
                        if (item[inp.name]) {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={inp.sm}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              key={i}
                            >
                              <DetailTextfield
                                title={inp.title}
                                value={item[inp.name]}
                              />
                            </Grid>
                          );
                        }
                      })}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
}
