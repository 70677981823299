import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import FolderIcon from "@material-ui/icons/Folder";
import CardIcon from "components/Card/CardIcon";
import axios from "axios";
import Forward from "@material-ui/icons/Forward";
import { Button } from "@material-ui/core";
import ReactTable from "components/ReactTable/ReactTable.js";

export default function TablePassdown(props) {
  const [informes, setInformes] = useState([]);

  const estado = props.match.path.slice(-1);

  const initData = async () => {
    await axios
      // .get(`http://localhost:5050/api/passdown?estado=${estado}`)
      .get(`https://epstool.com/api/passdown?estado=${estado}`)
      .then((res) => {
        setInformes(res.data);
      });
  };

  useEffect(() => {
    initData();
  }, []);

  const detailPassdown = (id) => {
    props.history.push(`/admin/informe-detail/${id}`);
  };
  const editPassdown = (id) => {
    props.history.push(`/admin/passdown-edit/${id}`, { isEdit: true });
  };

  return (
    <Card>
      <CardHeader color="success" icon>
        <CardIcon color="success">
          <FolderIcon />
        </CardIcon>
        <h4 style={{ color: "#000" }}>
          {estado === "1" ? "Borradores" : "Informes"}
        </h4>
      </CardHeader>
      <CardBody>
        <ReactTable
          columns={[
            {
              Header: "Id",
              accessor: "id",
            },
            {
              Header: "Encargado",
              accessor: "encargado",
            },
            {
              Header: "Fecha",
              accessor: "fecha",
            },
            {
              Header: "Turno",
              accessor: "turno",
            },
            {
              Header: "Actions",
              accessor: "actions",
            },
          ]}
          data={informes?.map((el, i) => {
            const fecha = new Date(el.fecha).toLocaleDateString();
            return {
              id: i + 1,
              encargado: el.encargado,
              fecha: fecha,
              turno: el.turno.toUpperCase(),
              actions: (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    onClick={
                      estado === "1"
                        ? () => editPassdown(el._id)
                        : () => detailPassdown(el._id)
                    }
                    style={{ color: "#4caf50" }}
                  >
                    <Forward />
                  </Button>
                </div>
              ),
            };
          })}
        />
      </CardBody>
    </Card>
  );
}
