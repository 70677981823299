import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { arrOfInformes } from "_mock/_passdown";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ReactFilestack from "filestack-react";
import usePassdown from "../hooks/usePassdown";
import {
  Box,
  Button,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { turnos } from "_mock/_passdown";
import Danger from "components/Typography/Danger";
import { Close } from "@material-ui/icons";
import Accordion from "components/Accordion/Accordion";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function EditarInforme(props) {
  const {
    transformDataToEdit,
    validationForm,
    orderDataToSend,
  } = usePassdown();
  const { id } = props.match.params;
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const [form, setForm] = useState();
  const [openFiles, setOpenFiles] = useState(false);
  const [urlFile, setUrlFile] = useState([]);
  const [urlFileError, setUrlFileError] = useState();
  const [inputName, setInputName] = useState("");
  const options = {
    maxFiles: 1,
    fromSources: ["local_file_system", "audio"],
  };

  const initData = async () => {
    // await axios.get(`http://localhost:5050/api/passdown/${id}`).then((res) => {
    await axios.get(`https://epstool.com/api/passdown/${id}`).then((res) => {
      const dataToForm = transformDataToEdit(res.data);
      setForm(dataToForm);
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeFile = () => {
    if (!!form[inputName]) {
      setForm({
        ...form,
        [inputName]: [...form[inputName], ...urlFile],
      });
    } else {
      setForm({
        ...form,
        [inputName]: urlFile,
      });
    }
    setOpenFiles(false);
    setUrlFile([]);
  };

  const handleOpenModal = (id) => {
    setOpenFiles(true);
    setInputName(id);
  };

  const handleCloseModal = () => {
    setOpenFiles(false);
    setUrlFile([]);
  };

  const handleDeleteFile = (item) => {
    const filteredData = urlFile?.filter((el) => el.url !== item);
    setUrlFile(filteredData);
  };

  const handleDeleteSaveFile = async (item, x) => {
    const input = form[item];
    const filteredData = input?.filter((el) => el.url !== x);
    setForm({ ...form, [item]: filteredData });
  };

  const handleEdit = async (estado) => {
    const dataToSave = orderDataToSend(form, form["encargado"], estado);
    await axios
      // .put(`http://localhost:5050/api/passdown/${id}`, dataToSave)
      .put(`https://epstool.com/api/passdown/${id}`, dataToSave)
      .then(() => {
        props.history.push(`/admin/passdown-informes/${estado}`);
      });
  };

  const handleSubmit = async () => {
    const errores = validationForm(form);
    delete errores.isActiveDescripcion;
    if (Object.keys(errores).length > 0) {
      await handleEdit(1);
    } else {
      await handleEdit(2);
    }
  };

  return (
    <Card>
      <CardHeader icon>
        <CardIcon color="success">
          <AssignmentIcon />
        </CardIcon>
        <h4 style={{ color: "#000" }}>Editar informe</h4>
      </CardHeader>
      <CardBody>
        {form && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <FormLabel>Encargado</FormLabel>
              <TextField
                inputProps={{ readOnly: true }}
                value={form["encargado"] ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormLabel>Fecha</FormLabel>
              <TextField
                inputProps={{ readOnly: true }}
                value={new Date(form["fecha"]).toLocaleDateString() ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormLabel>Turno</FormLabel>
              <Select
                fullWidth
                value={form["turno"] ?? ""}
                name="turno"
                onChange={(e) => handleChange(e)}
              >
                {turnos?.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.nombre}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <FormLabel>Pendientes</FormLabel>
              <TextField
                name="pendientes"
                onChange={(e) => handleChange(e)}
                variant="outlined"
                rows={5}
                value={form["pendientes"] ?? ""}
                multiline
                fullWidth
              />
            </Grid>
            {arrOfInformes?.map((el) => (
              <React.Fragment key={el.id}>
                <Grid item xs={12}>
                  <h5 style={{ fontWeight: "bold" }}>{el.title}</h5>
                </Grid>

                {el.sections?.map((item) => (
                  <React.Fragment key={item.id}>
                    {item.type === "radio" && (
                      <Grid item xs={12} sm={6}>
                        <FormLabel>{item.label}</FormLabel>
                      </Grid>
                    )}
                    {item.type === "radio" && (
                      <React.Fragment>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <FormControl>
                            <RadioGroup
                              name={item.cod}
                              row
                              value={form[item.cod] ?? ""}
                              onChange={(e) => handleChange(e)}
                            >
                              <FormControlLabel
                                value="normal"
                                control={<Radio />}
                                label="Normal"
                              />
                              <FormControlLabel
                                value="evento"
                                control={<Radio />}
                                label="Evento"
                              />
                            </RadioGroup>
                          </FormControl>
                          <Tooltip title="Cargar imagen">
                            <IconButton
                              onClick={() =>
                                handleOpenModal(`${item.cod}Imagen`)
                              }
                            >
                              <CloudUploadIcon />
                            </IconButton>
                          </Tooltip>
                          {form[`${item.cod}Imagen`]?.length > 0 && (
                            <Box width={120} marginLeft={{ sm: 3 }}>
                              <Accordion
                                collapses={[
                                  {
                                    title: "imagenes",
                                    content: (
                                      <List>
                                        {form[`${item.cod}Imagen`]?.map(
                                          (image) => (
                                            <ListItem key={image.url}>
                                              <ListItemText
                                                primary={
                                                  <a
                                                    href={image.url}
                                                    target="_blank"
                                                  >
                                                    {image.fileName}
                                                  </a>
                                                }
                                              />
                                              <ListItemIcon
                                                onClick={() =>
                                                  handleDeleteSaveFile(
                                                    `${item.cod}Imagen`,
                                                    image.url
                                                  )
                                                }
                                              >
                                                <Close
                                                  style={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </ListItemIcon>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    ),
                                  },
                                ]}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Descripción</FormLabel>
                          <TextField
                            name={item.cod + "Descripcion"}
                            onChange={(e) => handleChange(e)}
                            value={form[item.cod + "Descripcion"] ?? ""}
                            fullWidth
                            multiline
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    {item.type === "text" && (
                      <Grid
                        item
                        xs={12}
                        sm={item.width}
                        style={{
                          marginTop: "3rem",
                          marginBottom: "3rem",
                        }}
                      >
                        <FormLabel style={{ color: "#000", marginRight: 20 }}>
                          {item.label}
                        </FormLabel>

                        <TextField
                          type="number"
                          name={item.cod}
                          onChange={(e) => handleChange(e)}
                          value={form[item.cod] ?? ""}
                          onCopy={(e) => {
                            e.preventDefault();
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        )}
      </CardBody>
      <CardActions>
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" color="error"></Typography>

          <Box>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                marginTop: 5,
                marginBottom: 5,
              }}
              size="large"
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </CardActions>

      <Dialog
        open={openFiles}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
      >
        <DialogTitle disableTypography>
          <h4>Agregar archivo</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Grid
            container
            style={{
              minWidth: 300,
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <ReactFilestack
              apikey={API_KEY}
              onSuccess={(result) =>
                setUrlFile([
                  ...urlFile,
                  {
                    fileName: result.filesUploaded[0].filename,
                    url: result.filesUploaded[0].url,
                  },
                ])
              }
              onError={(error) =>
                setUrlFileError(
                  "Se ha producido un error, porfavor intentarlo nuevamente"
                )
              }
              actionOptions={options}
              customRender={({ onPick }) => (
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={onPick}
                    style={{
                      backgroundColor: "#42a5f5",
                      color: "#fff",
                    }}
                  >
                    Cargar archivo
                  </Button>
                  {urlFile?.length > 0 ? (
                    <List
                      subheader={
                        <ListSubheader>Archivos Cargados</ListSubheader>
                      }
                    >
                      {urlFile.map((fileItem) => (
                        <ListItem key={fileItem.url}>
                          <ListItemText primary={fileItem.fileName} />
                          <ListItemIcon
                            onClick={() =>
                              handleDeleteFile(fileItem.url, urlFile)
                            }
                          >
                            <Close
                              style={{
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                  ) : null}
                  {urlFileError ? (
                    <Danger>
                      <p>{urlFileError}</p>
                    </Danger>
                  ) : null}
                </div>
              )}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="contained" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
            size="large"
            onClick={handleChangeFile}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
