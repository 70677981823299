import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import Place from "@material-ui/icons/Place";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import Danger from "components/Typography/Danger";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Snackbar from "components/Snackbar/Snackbar.js";
import Close from "@material-ui/icons/Close";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InformeForm(props) {
  const classes = useStyles();
  const decoded = jwt_decode(localStorage.jwtToken);

  const idRonda = props.match.params.idronda;
  const idMaq = props.match.params.idmaq;

  const idForm = props.match.params.idform;
  const idNfc = props.match.params.idnfc;
  const [comentario, setComentario] = React.useState("");
  const [equipo, setEquipo] = React.useState();
  const [variables, setVariables] = React.useState();
  const [tc, setTC] = React.useState(false);
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [txtSaltar, setTxtSaltar] = useState("");
  const [errSaltar, setErrSaltar] = useState("");
  const [saltarModal, setSaltarModal] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [savingModal, setSavingModal] = React.useState(false);

  const [form, setForm] = React.useState([
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
    { nombre: "", valor: "", comentario: "" },
  ]);
  const [errors, setErrors] = React.useState([
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
    { nombre: "", valor: "" },
  ]);

  // CARGA DEL EQUIPO
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/maquina/${idMaq}`
      `https://epstool.com/api/rondascooper/maquina/${idMaq}`
    );
    console.log(result.data);
    setEquipo(result.data);
    if (idForm === result.data.tipo._id) {
      if (result.data.variablesPersonalizadas === true) {
        setVariables(result.data.variables);
        setForm(
          result.data.variables.map((element, index) => ({
            id: index,
            nombre: element.nombre,
            tipo: element.tipo,
            graficar: element.graficar,
            valor: "",
            comentario: "",
          }))
        );
      } else {
        setVariables(result.data.tipo.variables);
        setForm(
          result.data.tipo.variables.map((element, index) => ({
            id: index,
            nombre: element.nombre,
            tipo: element.tipo,
            graficar: element.graficar,
            valor: "",
            comentario: "",
          }))
        );
      }
    } else {
      let flag = 0;
      for (let index = 0; index < result.data.tiposExtra.length; index++) {
        const element = result.data.tiposExtra[index]._id;
        if (idForm === element) {
          flag = flag + 1;
          setVariables(result.data.tiposExtra[index].variables);
          // console.log(result.data.tiposExtra[index].variables);
          setForm(
            result.data.tiposExtra[index].variables.map((element, index) => ({
              id: index,
              nombre: element.nombre,
              tipo: element.tipo,
              graficar: element.graficar,
              valor: "",
              comentario: "",
            }))
          );
          break;
        }
      }
      if (flag === 0) {
        props.history.push(`../../../qrcode/equipo/${result.data.uuid}`);
      }
    }
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleCustomInput = async (event, id, tipo) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (tipo) {
      case 0:
        form[id].valor = txt;
        setForm([...form]);
        break;
      case 1:
        form[id].comentario = txt;
        setForm([...form]);
        break;
      default:
        break;
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    let flag = verificar(form);

    if (flag === 0) {
      setSavingModal(true);

      let numberHallazgos = 0;
      form.forEach((element) =>
        element.comentario ? (numberHallazgos = numberHallazgos + 1) : null
      );

      const informeToSave = {
        ronda: idRonda,
        data: form,
        comentarios: comentario,
        maquina: idMaq,
        idmaq: equipo,
        flagHallazgos: numberHallazgos,
        tipoMaq: idForm,
        idUser: decoded.id,
        userName: decoded.name,
      };

      await axios
        // .post("http://localhost:5005/api/rondascooper/informe", informeToSave)
        .post("https://epstool.com/api/rondascooper/informe", informeToSave)
        .then((res) => {
          setComentario("");
          setForm(
            variables.map((element, index) => ({
              id: index,
              nombre: element.nombre,
              tipo: element.tipo,
              graficar: element.graficar,
              valor: "",
              comentario: "",
            }))
          );
          setSavingModal(false);
          setSuccessModal(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const verificar = (inputs) => {
    let errorMessage = [{}];
    let errors = 0;

    inputs.forEach((element) => {
      errorMessage = [...errorMessage, {}];
      if (!element.valor) {
        errorMessage[element.id].valor =
          "Debe escribir el valor de esta variable";
        errors = errors + 1;
      } else {
        errorMessage[element.id].valor = "";
      }
    });
    setErrors([...errorMessage]);
    return errors;
  };

  const handleSaltarSubmit = async (event) => {
    if (txtSaltar.length <= 5) {
      setErrSaltar("La justificación debe tener más de 5 caracteres");
    } else {
      setErrSaltar();
      const informeToSave = {
        ronda: idRonda,
        comentarios: `El equipo ha sido omitido: ${txtSaltar}`,
        maquina: idMaq,
        idmaq: equipo,
        flagHallazgos: 1,
        user: decoded.name,
      };
      setSaltarModal(false);
      setSavingModal(true);
      await axios
        // .post("http://localhost:5005/api/rondascooper/omitir", informeToSave)
        .post("https://epstool.com/api/rondascooper/omitir", informeToSave)
        .then((res) => {
          setTxtSaltar("");
          setForm(
            variables.map((element, index) => ({
              id: index,
              nombre: element.nombre,
              tipo: element.tipo,
              graficar: element.graficar,
              valor: "",
              comentario: "",
            }))
          );
          setSaltarModal(false);
          setSavingModal(false);
          setSuccessModal(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  let formulario;

  variables
    ? (formulario = variables.map((element, index) => (
        <div key={element._id}>
          {element.tipo === 1 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  {element.nombre}*:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  multiline
                  labelText="Ej: Temperatura"
                  id={element._id.toString()}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleCustomInput(e, index, 0),
                    type: "text",
                    value: form[index].valor,
                  }}
                />
              </GridItem>
            </GridContainer>
          ) : null}
          {element.tipo === 2 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  <b>{element.nombre}*:</b>
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  multiline
                  labelText="Digite valor solicitado"
                  id={element._id.toString()}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => handleCustomInput(e, index, 0),
                    //   onChange: (e) => handleCustomInput(e, index, 1),
                    type: "number",
                    value: form[index].valor,
                    //   value: form[index].valor,
                  }}
                />
                <Danger>
                  {errors[index].valor === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors[index].valor}
                    </div>
                  )}
                </Danger>
              </GridItem>
              {element.minimo ? (
                <GridItem xs={6} sm={2}>
                  <p className={classes.labelCustomHorizontal}>
                    Min: {element.minimo}
                  </p>
                </GridItem>
              ) : null}
              {element.maximo ? (
                <GridItem xs={6} sm={2}>
                  <p className={classes.labelCustomHorizontal}>
                    Max: {element.maximo}
                  </p>
                </GridItem>
              ) : null}
              {element.minimo &&
              form[index].valor.length >= 1 &&
              (form[index].valor < element.minimo ||
                form[index].valor > element.maximo) ? (
                <GridItem xs={12} sm={2}>
                  <FormLabel className={classes.labelCustomHorizontal}>
                    <b>Comentario:</b>
                  </FormLabel>
                </GridItem>
              ) : null}
              {element.minimo &&
              form[index].valor.length >= 1 &&
              (form[index].valor < element.minimo ||
                form[index].valor > element.maximo) ? (
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    multiline
                    labelText="Detalle el valor ingresado"
                    id={element._id.toString()}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => handleCustomInput(e, index, 1),
                      type: "text",
                      value: form[index].comentario,
                    }}
                  />
                </GridItem>
              ) : null}
              <GridItem xs={12}>
                <Divider />
              </GridItem>
            </GridContainer>
          ) : null}
          {element.tipo === 3 ? (
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel
                  className={
                    classes.labelCustomHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  <b>{element.nombre}*:</b>
                </FormLabel>
              </GridItem>
              <GridItem xs={2} sm={1} md={1} lg={1}></GridItem>
              <GridItem xs={10} sm={7}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          element.boolAfirmativo
                            ? form[index].valor === element.boolAfirmativo
                            : form[index].valor === "SI"
                        }
                        onChange={(e) => handleCustomInput(e, index, 0)}
                        value={
                          element.boolAfirmativo ? element.boolAfirmativo : "SI"
                        }
                        name="radio button demo"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={
                      element.boolAfirmativo ? element.boolAfirmativo : "SI"
                    }
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          element.boolNegativo
                            ? form[index].valor === element.boolNegativo
                            : form[index].valor === "NO"
                        }
                        onChange={(e) => handleCustomInput(e, index, 0)}
                        value={
                          element.boolNegativo ? element.boolNegativo : "NO"
                        }
                        name="radio button demo"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={element.boolNegativo ? element.boolNegativo : "NO"}
                  />
                </div>
                <Danger>
                  {errors[index].valor === undefined ? null : (
                    <div className="invalid-feedback">
                      {errors[index].valor}
                    </div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
          ) : null}
        </div>
      )))
    : (formulario = "");

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Informe del equipo {equipo ? equipo.codMaquina : ""} - Ronda{" "}
              {idRonda}
            </h4>
            <Button
              size="sm"
              color="info"
              onClick={() => {
                setSaltarModal(true);
              }}
            >
              Omitir informe
            </Button>
          </CardHeader>
          <CardBody>
            {formulario}
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Comentarios:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  multiline
                  labelText=""
                  id="coment"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    onChange: (e) => setComentario(e.target.value),
                    type: "text",
                    value: comentario,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <Button fullWidth color="success" onClick={handleSave}>
                Guardar Informe
              </Button>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      {/* Omitir NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={saltarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSaltarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setSaltarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Omitir equipo</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>Justificación:</p>
          <CustomInput
            multiline
            labelText="Escribir justificación"
            id="txtSaltar"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTxtSaltar(event.target.value);
              },
              type: "text",
              value: txtSaltar,
            }}
          />
          <Danger>
            {errSaltar === undefined ? null : (
              <div className="invalid-feedback">{errSaltar}</div>
            )}
          </Danger>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleSaltarSubmit} color="success">
            Guardar
          </Button>
          <Button onClick={() => setSaltarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal Success */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={successModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSuccessModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setSuccessModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Informe almacenado con éxito</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>Escoja una acción a realizar:</p>
          <Button
            onClick={() => {
              props.history.push(`../../../../../admin/qrscanner`);
            }}
            block
            color="success"
          >
            Abrir Scanner
          </Button>
          <Button
            onClick={() => {
              props.history.push(
                `../../../../../admin/qrcode/equipo/${equipo.uuid}`
              );
            }}
            block
            color="danger"
          >
            Volver al equipo {equipo ? equipo.codMaquina : ""}
          </Button>
        </DialogContent>
      </Dialog>
      {/* Enviando Informe */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={savingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSavingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        {/* <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setSavingModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Informe almacenado con éxito</h4>
        </DialogTitle> */}
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>Guardando informe, espere un momento...</p>
          {/* <Button
            onClick={() => {
              props.history.push(`../../qrscanner`);
            }}
            block
            color="success"
          >
            Abrir Scanner
          </Button>
          <Button
            onClick={() => {
              props.history.push(`../../qrcode/equipo/${equipo.uuid}`);
            }}
            block
            color="danger"
          >
            Volver al equipo {equipo ? equipo.codMaquina : ""}
          </Button> */}
        </DialogContent>
      </Dialog>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </GridContainer>
  );
}
