import { Box, FormLabel, Grid, TextField } from "@material-ui/core";
import React from "react";

export default function DetailTextfield({ title, value, fullwidth }) {
  return (
    <Grid
      style={{
        height: 100,
        display: "block",
        alignItems: "center",
      }}
    >
      <Box>
        <FormLabel style={{ color: "#000" }}>{title}</FormLabel>
      </Box>

      <TextField
        variant="standard"
        fullWidth={fullwidth ?? false}
        value={value}
        multiline
      />
    </Grid>
  );
}
