import { useState, useEffect } from "react";
import axios from "axios";
import { arrOfInformes } from "_mock/_passdown";
import { defaultValues } from "_mock/_passdown";

export default function usePassdown() {
  const [informes, setInformes] = useState([]);

  const initData = async () => {
    await axios
      // .get(`http://localhost:5050/api/passdown?estado=${1}`)
      .get(`https://epstool.com/api/passdown?estado=${1}`)
      .then((res) => {
        setInformes(res.data);
      });
  };

  useEffect(() => {
    initData();
  }, []);

  const stockManagement = (estado, encargado) => {
    console.log(estado);
    const dataFiltered = informes.find(
      (el) => el.estado == estado && el.encargado === encargado
    );

    if (dataFiltered !== undefined && Object.keys(dataFiltered).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const orderDataToSend = (data, name, estado) => {
    return {
      encargado: name ?? "",
      fecha: new Date() ?? "",
      turno: data?.turno ?? "",
      pendientes: data?.pendientes ?? "",
      estado: estado ?? 2,
      mecanico: {
        HVACProductionRoom: {
          isActive: data?.hvac ?? "",
          files: data?.hvacImagen ?? [], // Array de archivos
          description: data?.hvacDescripcion ?? "", // Descripción para eventos
        },
        AirExtractionSystems: {
          isActive: data?.extraccion ?? "",
          files: data?.extraccionImagen ?? [],
          description: data?.extraccionDescripcion ?? "",
        },
        CHWSystem: {
          isActive: data?.chw ?? "",
          files: data?.chwImagen ?? [],
          description: data?.chwDescripcion ?? "",
          appro1: data?.appro1 ? Number(data?.appro1) : null,
          appro2: data?.appro2 ? Number(data?.appro2) : null,
        },
        CWSystem: {
          isActive: data?.cw ?? "",
          files: data?.cwImagen ?? [],
          description: data?.cwDescripcion ?? "",
        },
        ProcessWaterSystem: {
          isActive: data?.agua ?? "",
          files: data?.aguaImagen ?? [],
          description: data?.aguaDescripcion ?? "",
        },
        ChemicalTreatmentSystem: {
          isActive: data?.tratamiento ?? "",
          files: data?.tratamientoImagen ?? [],
          description: data?.tratamientoDescripcion ?? "",
        },
        CompressedAirSystem: {
          isActive: data?.aire ?? "",
          files: data?.aireImagen ?? [],
          description: data?.aireDescripcion ?? "",
        },
        WaterSofteners: {
          isActive: data?.suavizadores ?? "",
          files: data?.suavizadoresImagen ?? [],
          description: data?.suavizadoresDescripcion ?? "",
          ppm: data?.ppm ? Number(data?.ppm) : null,
        },
        UltrapureWaterSystems: {
          isActive: data?.aguaUltraPura ?? "",
          files: data?.aguaUltraPuraImagen ?? [],
          description: data?.aguaUltraPuraDescripcion ?? "",
          ups1: data?.ups1 ? Number(data?.ups1) : null,
          ups2: data?.ups2 ? Number(data?.ups2) : null,
          ups3: data?.ups3 ? Number(data?.ups3) : null,
        },
        DrinkingWaterSystem: {
          isActive: data?.aguaPotable ?? "",
          files: data?.aguaPotableImagen ?? [],
          description: data?.aguaPotableDescripcion ?? "",
          cloro: data?.cloro ? Number(data?.cloro) : null,
        },
        FireProtectionSystems: {
          isActive: data?.contraIncendio ?? "",
          files: data?.contraIncendioImagen ?? [],
          description: data?.contraIncendioDescripcion ?? "",
          diesel: data?.dieselComplementary
            ? Number(data?.dieselComplementary)
            : null,
        },
        SteamSystem: {
          isActive: data?.vapor ?? "",
          files: data?.vaporImagen ?? [],
          description: data?.vaporDescripcion ?? "",
        },
        GasSystems: {
          isActive: data?.gas ?? "",
          files: data?.gasImagen ?? [],
          description: data?.gasDescripcion ?? "",
          nivel: data?.nivel ? Number(data?.nivel) : null,
        },
        VacuumSystem: {
          isActive: data?.vacio ?? "",
          files: data?.vacioImagen ?? [],
          description: data?.vacioDescripcion ?? "",
        },
        ResinSystem: {
          isActive: data?.resina ?? "",
          files: data?.resinaImagen ?? [],
          description: data?.resinaDescripcion ?? "",
          silo1: data?.silo1 ? Number(data?.silo1) : null,
          silo2: data?.silo2 ? Number(data?.silo2) : null,
          silo3: data?.silo3 ? Number(data?.silo3) : null,
          silo4: data?.silo4 ? Number(data?.silo4) : null,
          silo5: data?.silo5 ? Number(data?.silo5) : null,
          silo6: data?.silo6 ? Number(data?.silo6) : null,
          silo7: data?.silo7 ? Number(data?.silo7) : null,
          silo8: data?.silo8 ? Number(data?.silo8) : null,
        },
        HVACOffices: {
          isActive: data?.hvacOficinas ?? "",
          files: data?.hvacOficinasImagen ?? [],
          description: data?.hvacOficinasDescripcion ?? "",
        },
        WalkInCooler: {
          isActive: data?.cooler ?? "",
          files: data?.coolerImagen ?? [],
          description: data?.coolerDescripcion ?? "",
        },
      },
      electrico: {
        PowerSupply: {
          isActive: data?.ice ?? "",
          files: data?.iceImagen ?? [], // Array de archivos
          description: data?.iceDescripcion ?? "", // Descripción para eventos
        },
        Switchgear: {
          isActive: data?.seccionadora ?? "",
          files: data?.seccionadoraImagen ?? [],
          description: data?.seccionadoraDescripcion ?? "",
        },
        GeneralTransformers: {
          isActive: data?.transformadores ?? "",
          files: data?.transformadoresImagen ?? [],
          description: data?.transformadoresDescripcion ?? "",
        },
        ElectricalSubstation: {
          isActive: data?.subestacion ?? "",
          files: data?.subestacionImagen ?? [],
          description: data?.subestacionDescripcion ?? "",
        },
        EmergencyGenerators: {
          isActive: data?.generadores ?? "",
          files: data?.generadoresImagen ?? [],
          description: data?.generadoresDescripcion ?? "",
        },
        ElectricalPanels: {
          isActive: data?.tableros ?? "",
          files: data?.tablerosImagen ?? [],
          description: data?.tablerosDescripcion ?? "",
        },
        UPS: {
          isActive: data?.ups ?? "",
          files: data?.upsImagen ?? [],
          description: data?.upsDescripcion ?? "",
        },
        LightingSystem: {
          isActive: data?.iluminacion ?? "",
          files: data?.iluminacionImagen ?? [],
          description: data?.iluminacionDescripcion ?? "",
        },
        FireSuppressionSystem: {
          isActive: data?.supresion ?? "",
          files: data?.supresionImagen ?? [],
          description: data?.supresionDescripcion ?? "",
        },
        FireNotificationSystem: {
          isActive: data?.notificacion ?? "",
          files: data?.notificacionImagen ?? [],
          description: data?.notificacionDescripcion ?? "",
        },
        DieselSystem: {
          isActive: data?.diesel ?? "",
          files: data?.dieselImagen ?? [],
          description: data?.dieselDescripcion ?? "",
        },
      },
      dms: {
        isActive: data?.bms ?? "",
        files: data?.bmsImagen ?? [], // Array de archivos
        description: data?.bmsDescripcion ?? "", // Descripción para eventos
      },
      edificio: {
        CleanRooms: {
          isActive: data?.cuartosLimpios ?? "",
          files: data?.cuartosLimpiosImagen ?? [],
          description: data?.cuartosLimpiosDescripcion ?? "",
        },
        BuildingCleaningAndOrnamentation: {
          isActive: data?.aseo ?? "",
          files: data?.aseoImagen ?? [],
          description: data?.aseoDescripcion ?? "",
        },
        RegularBuildingMaintenanceTasks: {
          isActive: data?.tareasRegulares ?? "",
          files: data?.tareasRegularesImagen ?? [],
          description: data?.tareasRegularesDescripcion ?? "",
        },
        PestControlSystem: {
          isActive: data?.plagas ?? "",
          files: data?.plagasImagen ?? [],
          description: data?.plagasDescripcion ?? "",
        },
      },
    };
  };

  const validationForm = (formulario) => {
    const errores = {};
    // Recorre las propiedades del objeto
    for (const campo in formulario) {
      // Verifica si el campo es de tipo 'evento' o normal
      if (formulario[campo] === "evento" || formulario[campo] === "normal") {
        // Construye el nombre del campo de comentario
        const campoDescripcion = `${campo}Descripcion`;

        // Verifica si existe y si está vacío
        if (
          !formulario[campoDescripcion] ||
          formulario[campoDescripcion] === ""
        ) {
          // Guarda el error en el objeto de errores
          errores[campoDescripcion] = `Este campo es obligatorio`;
        }
      }
    }

    for (const campo in defaultValues) {
      if (!formulario[campo] || formulario[campo] === "") {
        errores[campo] = `Este campo es obligatorio`;
      }
    }
    if (errores.isActiveDescripcion) {
      delete errores.isActiveDescripcion;
    }

    if (errores.isActiveComplementaryDescripcion) {
      delete errores.isActiveComplementaryDescripcion;
    }

    // Muestra los errores (puedes ajustar esto según tus necesidades)
    if (Object.keys(errores).length > 0) {
      console.log(errores);
    } else {
      console.log("Validación exitosa.");
    }

    return errores;
  };

  const orderData = (data) => {
    const result = [];
    const inputData = { ...data };
    // Procesar las propiedades principales
    if (!inputData) {
      return;
    }

    const mainProperties = Object.keys(inputData).filter(
      (key) => typeof inputData[key] === "string"
    );

    const mainObject = {};
    mainProperties.forEach((property) => {
      mainObject[property] = inputData[property];
      delete inputData[property];
    });
    result.push(mainObject);

    // Procesar las propiedades que son objetos
    Object.keys(inputData).forEach((sectionKey) => {
      const section = {
        titulo: sectionKey === "dms" ? "bms" : sectionKey,
        section: [],
      };
      const properties = Object.keys(inputData[sectionKey]);
      if (!properties.includes("files" || "isActive" || "description")) {
        properties.forEach((property) => {
          section.section.push({
            ...inputData[sectionKey][property],
            name: property,
            label: "",
          });
        });
      } else {
        section.section.push({
          ...inputData[sectionKey],
          name: "bms",
          label: "",
        });
      }
      result.push(section);
    });
    result.pop();

    result.slice(1).map((el, index) => {
      for (let i = 0; i < el.section.length; i++) {
        const element = el.section[i];

        if (el.titulo === arrOfInformes[index].title.toLowerCase()) {
          const valor = arrOfInformes[index].sections.find(
            (item) => item.uuid === element.name
          );

          element.label = valor?.label ?? "Titulo no encontrado";
        }
      }
    });

    return result;
  };

  const transformDataToEdit = (data) => {
    if (!data) {
      return;
    }

    const emptyValues = { ...defaultValues, fecha: "", encargado: "" };

    for (const property in data) {
      if (emptyValues[property] !== undefined) {
        emptyValues[property] = data[property];
      }
    }

    for (let value of arrOfInformes) {
      const titulo =
        value.title.toLowerCase() === "bms" ? "dms" : value.title.toLowerCase();
      if (titulo === "dms" && data[titulo] !== undefined) {
        emptyValues["bms"] = data[titulo]?.isActive;
        emptyValues["bmsDescripcion"] = data[titulo]?.description;
        emptyValues["bmsImagen"] = data[titulo]?.files;
      }

      for (let key of value.sections) {
        const contentData = data[titulo]?.[key.uuid];
        if (contentData !== undefined) {
          emptyValues[key.cod] = contentData?.isActive;
          emptyValues[key.cod + "Descripcion"] = contentData?.description;
          emptyValues[key.cod + "Imagen"] = contentData?.files;
        }

        for (const llave in contentData) {
          emptyValues[llave] = contentData[llave];
          if (llave + "dieselComplementary") {
            emptyValues[llave + "Complementary"] = contentData[llave];
          }
        }
      }
    }

    return emptyValues;
  };

  return {
    orderDataToSend,
    validationForm,
    orderData,
    transformDataToEdit,
    stockManagement,
  };
}
