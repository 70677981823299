import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import Forward from "@material-ui/icons/Forward";

const useStyles = makeStyles(styles);

export default function ListaRondas(props) {
  const classes = useStyles();
  const [rondas, setRondas] = React.useState([]);
  const [rondaData, setRondaData] = React.useState([]);
  const [flagData, setFlagData] = React.useState();
  const [sortOptions, setSortOptions] = React.useState([
    { id: "codigo", desc: false },
  ]);
  // CARGA DE LA RONDA
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/allrondaconfi`
      `https://epstool.com/api/rondascooper/allrondaconfi`
    );
    console.log(result.data);
    setFlagData(0);
    setRondas(result.data);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const getRondas = async (id) => {
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/allrondasfilter/${id}`
      `https://epstool.com/api/rondascooper/allrondasfilter/${id}`
    );
    console.log(result.data);

    setRondaData(result.data);
    setFlagData(1);
  };

  let tbRondas;
  Object.keys(rondas).length >= 1
    ? (tbRondas = rondas.map((rondas) => {
        return {
          id: rondas._id,
          nombre: rondas.nombre,
          // tipo: rondas.tipo,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  getRondas(rondas._id);
                  // props.history.push(`maquina/${rondas._id}`);
                }}
                color="success"
                className="edit"
              >
                <Forward />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbRondas = []);

  let tbRonda;
  Object.keys(rondaData).length >= 1
    ? (tbRonda = rondaData.map((ronda) => {
        let dateTable;
        if (ronda.horaInicio) {
          dateTable = new Date(ronda.horaInicio * 1000);
        } else {
          dateTable = new Date(ronda.fechaInicio * 1000);
        }
        return {
          id: ronda._id,
          codigo: ronda.codigo,
          fecha: `${dateTable.getDate()}/${
            dateTable.getMonth() + 1
          }/${dateTable.getFullYear()}`,
          // tipo: rondas.tipo,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  // getRondas(ronda._id);
                  props.history.push(`rondadetalles/${ronda.codigo}`);
                }}
                color="success"
                className="edit"
              >
                <Forward />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbRonda = []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Place />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Lista de Rondas{" "}
              {flagData === 1 && rondaData[0]
                ? ` - Ronda ${rondaData[0].nombre}`
                : null}
            </h4>
            {flagData === 1 ? (
              <Button
                color="success"
                size="sm"
                onClick={() => {
                  setFlagData(0);
                }}
              >
                Volver a rondas
              </Button>
            ) : null}
          </CardHeader>
          <CardBody>
            {flagData === 0 ? (
              <ReactTable
                columns={[
                  {
                    Header: "Nombre",
                    accessor: "nombre",
                  },
                  // {
                  //   Header: "Tipo",
                  //   accessor: "tipo",
                  // },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={tbRondas}
              />
            ) : (
              <ReactTable
                columns={[
                  {
                    Header: "Codigo",
                    accessor: "codigo",
                  },
                  {
                    Header: "Fecha",
                    accessor: "fecha",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={tbRonda}
                defaultSorted={[
                  {
                    id: "codigo",
                    desc: false,
                  },
                ]}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
