import React, { useState, useEffect } from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function editarMaquina(props) {
  const idEquipo = props.match.params._id;
  // const [equipo, setEquipo] = useState();

  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/maquina/${idEquipo}`
      `https://epstool.com/api/rondascooper/maquina/${idEquipo}`
    );
    console.log(result);
    // setEquipo(result);
  };

  // // CARGA INICIAL
  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div>
      <GridContainer>
        <GridItem>
          <h2>Pagina para editar {idEquipo}</h2>
        </GridItem>
      </GridContainer>
    </div>
  );
}
