import React, { useState, useEffect, PureComponent } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
} from "recharts";
import ReactExport from "react-data-export";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Accordion from "components/Accordion/Accordion.js";
import Datetime from "react-datetime";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Snackbar from "components/Snackbar/Snackbar.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { saveAs } from "file-saver";

const useStyles = makeStyles(styles);

//Excel req
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AnalisisConfig(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [nameChecked, setNameChecked] = useState([]);
  const [tipoChecked, setTipoChecked] = useState([]);
  const [dataMaqs, setDataMaqs] = useState([{}]);
  const [dataOrdenada, setDataOrdenada] = useState([]);
  const [dataForExcel, setDataForExcel] = useState([]);
  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();
  const [loadingModal, setLoadingModal] = useState(false);
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [tc, setTC] = useState(false);
  const [flagCarga, setFlagCarga] = useState(0);
  const [flagExcel, setFlagExcel] = useState(0);

  const fetchData = async () => {
    const resultMaqs = await axios(
      "https://epstool.com/api/rondascooper/namesmaq"
      // "http://localhost:5005/api/rondascooper/namesmaq"
    );
    setDataMaqs(resultMaqs.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };
  const handleFrom = (e) => {
    setDesde(e.unix());
  };
  const handleTo = (e) => {
    setHasta(e.unix());
  };
  const handleToggle = (value, name, tipo) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newNameChecked = [...nameChecked];
    const newTipoChecked = [...tipoChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      newNameChecked.push(name);
      newTipoChecked.push(tipo);
    } else {
      newChecked.splice(currentIndex, 1);
      newNameChecked.splice(currentIndex, 1);
      newTipoChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setNameChecked(newNameChecked);
    setTipoChecked(newTipoChecked);
  };

  const handleGetAnalisis = async (event) => {
    setLoadingModal(true);
    await axios
      .get(
        `https://epstool.com/api/rondascooper/avanzado/${checked[0]}/${desde}/${hasta}`,
        // `http://localhost:5005/api/rondascooper/avanzado/${checked[0]}/${desde}/${hasta}`,
        {
          params: {
            equipos: checked,
          },
        }
      )
      .then((result) => {
        // let dataToGraf = [
        //   {
        //     tipo: "Recirculadora",
        //     maquinas: [
        //       { RAH19: [{ valores: [19, 30], label: [10000, 20000] }] },
        //     ],
        //   },
        // ];
        let dataToGraf = [];
        let objVariable = [];

        result.data.informesOrdenados.forEach((equipo) => {
          let idxNombreMaquina = checked.indexOf(equipo.name);
          let nombreEquipo = nameChecked[idxNombreMaquina];
          let tipoEquipo = tipoChecked[idxNombreMaquina];
          // let objVariable = { nombre: "", valores: []}

          // [{amperios: series:[{fecha: "", Rah15: 15 }]}]
          equipo.informes.forEach((informe) => {
            let date = new Date(informe.fecha * 1000);
            informe.informacion.forEach((variable) => {
              if (variable.tipo === 2) {
                try {
                  let idxVarGraf = objVariable.findIndex(
                    (e) => e.nombre === variable.nombre
                  );

                  if (idxVarGraf < 0) {
                    let obj = {};
                    obj["fecha"] = `${date.getDate()}/${date.getMonth() + 1}`;
                    obj[nombreEquipo] = parseInt(variable.valor);
                    objVariable.push({
                      nombre: variable.nombre,
                      series: [obj],
                    });
                  } else {
                    let obj = {};
                    obj["fecha"] = `${date.getDate()}/${date.getMonth() + 1}`;
                    obj[nombreEquipo] = parseInt(variable.valor);

                    objVariable[idxVarGraf].series.push(obj);
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            });
          });

          let idxTipoGraf = dataToGraf.findIndex(
            (e) => e.nombre === tipoEquipo
          );

          if (idxTipoGraf < 0) {
            dataToGraf.push({ nombre: tipoEquipo, valores: objVariable });
          }
          // else {
          //   dataToGraf[idxTipoGraf].valores.push(objVariable);
          // }

          // {recirculadora: { amperio: {labels[], series: {[]}}}}

          // if (!dataToGraf.hasOwnProperty(tipoEquipo)) {
          //   dataToGraf[tipoEquipo] = {
          //     maquinas: [
          //       {
          //         nombre: nombreEquipo,
          //         variables: objVariable,
          //       },
          //     ],
          //   };
          // } else {
          //   dataToGraf[tipoEquipo].maquinas.push({
          //     nombre: nombreEquipo,
          //     variables: objVariable,
          //   });
          // }

          // if (!dataToGraf.hasOwnProperty(tipoEquipo)) {
          //   dataToGraf[tipoEquipo] = {
          //     maquinas: [
          //       {
          //         nombre: nombreEquipo,
          //         variables: objVariable,
          //       },
          //     ],
          //   };
          // } else {
          //   dataToGraf[tipoEquipo].maquinas.push({
          //     nombre: nombreEquipo,
          //     variables: objVariable,
          //   });
          // }
        });
        let datos1 = [
          {
            nombre: "Recirculadora Aire ",
            valores: [
              {
                nombre: "Frecuencia",
                series: [
                  {
                    fecha: "14/8",
                    "RAH 19": 41,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "14/8",
                    "RAH 19": 42,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 42,
                    "RAH 21": 55,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 43,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 41,
                    "RAH 21": 55,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 42,
                    "RAH 21": 55,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 42,
                    "RAH 21": 55,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 42,
                    "RAH 21": 55,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 43,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 43,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 44,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 43,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 42,
                    "RAH 21": 56,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 43,
                    "RAH 21": 55,
                  },
                ],
              },
              {
                nombre: "Amperios",
                series: [
                  {
                    fecha: "14/8",
                    "RAH 19": 23,
                    "RAH 21": 16,
                  },
                  {
                    fecha: "14/8",
                    "RAH 19": 23,
                    "RAH 21": 16,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 23,
                    "RAH 21": 17,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 26,
                    "RAH 21": 15,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 22,
                    "RAH 21": 17,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 23,
                    "RAH 21": 17,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 24,
                    "RAH 21": 14,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 24,
                    "RAH 21": 15,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 25,
                    "RAH 21": 18,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 23,
                    "RAH 21": 16,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 25,
                    "RAH 21": 18,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 27,
                    "RAH 21": 17,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 26,
                    "RAH 21": 19,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 27,
                    "RAH 21": 17,
                  },
                ],
              },
              {
                nombre: "Temperatura Entrada Agua",
                series: [
                  {
                    fecha: "14/8",
                    "RAH 19": 44,
                    "RAH 21": 43,
                  },
                  {
                    fecha: "14/8",
                    "RAH 19": 42,
                    "RAH 21": 43,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 43,
                    "RAH 21": 43,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 44,
                    "RAH 21": 44,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 42,
                    "RAH 21": 42,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 42,
                    "RAH 21": 42,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 44,
                    "RAH 21": 43,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 42,
                    "RAH 21": 42,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 44,
                    "RAH 21": 44,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 42,
                    "RAH 21": 42,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 44,
                    "RAH 21": 44,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 42,
                    "RAH 21": 44,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 44,
                    "RAH 21": 44,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 42,
                    "RAH 21": 42,
                  },
                ],
              },
              {
                nombre: "Temperatura Salida Agua",
                series: [
                  {
                    fecha: "14/8",
                    "RAH 19": 60,
                    "RAH 21": 60,
                  },
                  {
                    fecha: "14/8",
                    "RAH 19": 60,
                    "RAH 21": 59,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 60,
                    "RAH 21": 70,
                  },
                  {
                    fecha: "13/8",
                    "RAH 19": 62,
                    "RAH 21": 64,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 64,
                    "RAH 21": 66,
                  },
                  {
                    fecha: "12/8",
                    "RAH 19": 64,
                    "RAH 21": 60,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 65,
                    "RAH 21": 70,
                  },
                  {
                    fecha: "11/8",
                    "RAH 19": 60,
                    "RAH 21": 58,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 60,
                    "RAH 21": 60,
                  },
                  {
                    fecha: "10/8",
                    "RAH 19": 60,
                    "RAH 21": 60,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 60,
                    "RAH 21": 60,
                  },
                  {
                    fecha: "9/8",
                    "RAH 19": 62,
                    "RAH 21": 62,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 65,
                    "RAH 21": 60,
                  },
                  {
                    fecha: "8/8",
                    "RAH 19": 60,
                    "RAH 21": 60,
                  },
                ],
              },
              {
                nombre: "Presion de Filtros de Entrada",
                series: [
                  {
                    fecha: "10/8",
                    "RAH 19": 0,
                  },
                ],
              },
              {
                nombre: "Presion de Filtros de Salida",
                series: [
                  {
                    fecha: "10/8",
                    "RAH 19": 0,
                  },
                ],
              },
            ],
          },
        ];
        setDataOrdenada(datos1);
        console.log(dataToGraf);
        setFlagCarga(1);
        setLoadingModal(false);
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  const handleGetPDFEquipos = async (event) => {
    setLoadingModal(true);
    await axios
      .get(
        `https://epstool.com/api/rondascooper/avanzado/${checked[0]}/${desde}/${hasta}`,
        // `http://localhost:5005/api/rondascooper/avanzado/${checked[0]}/${desde}/${hasta}`,
        {
          params: {
            equipos: checked,
          },
        }
      )
      .then((result) => {
        console.log(result.data.informesOrdenados);
        result.data.informesOrdenados.forEach((equipo) => {
          let idxNombreMaquina = checked.indexOf(equipo.name);
          let nombreEquipo = nameChecked[idxNombreMaquina];
          let tipoEquipo = tipoChecked[idxNombreMaquina];
          equipo.nombre = nombreEquipo;
          equipo.tipo = tipoEquipo;
        });
        let valoresPdf = "";
        console.log(result.data.informesOrdenados);
        result.data.informesOrdenados.forEach((maquina) => {
          let stringMaquina = `<p><strong> Maquina ${maquina.nombre} </strong></p>`;
          valoresPdf += stringMaquina;
          maquina.rondas.forEach((ronda) => {
            let stringRonda = `<p> Ronda ${ronda.nombre}</p>`;
            valoresPdf += stringRonda;
            ronda.informes.forEach((informe) => {
              let date = new Date(informe.fecha * 1000);

              let stringInforme = `<p> Codigo ${
                informe.codigo
              }</p> <p>${date.getDate()}/${
                date.getMonth() + 1
              }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}</p>`;
              valoresPdf += stringInforme;
              let stringLista = "";
              informe.informacion.forEach((info) => {
                let stringInfo = `<li>${info.nombre}: ${info.valor}</li>`;
                stringLista += stringInfo;
              });
              let string = `<ul> ${stringLista} </ul>`;
              valoresPdf += string;
            });
          });
        });

        const equiposPDF = { equipos: valoresPdf };

        axios
          .post(
            "https://epstool.com/api/rondascooper/exportarEquipos",
            // "http://localhost:5005/api/rondascooper/exportarEquipos",
            equiposPDF
          )
          .then(() =>
            axios
              .get("https://epstool.com/api/rondascooper/fecth-pdfEquipo", {
              // .get("http://localhost:5005/api/rondascooper/fecth-pdfEquipo", {
                // .get("/api/maquinas/fecth-pdf", {
                responseType: "blob",
              })
              .catch((err) => console.log(err))
          )
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: "application/pdf" });

            saveAs(pdfBlob, `Reporte.pdf`);
            setLoadingModal(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };

  const handleGetExelEquipos = async (event) => {
    setLoadingModal(true);
    console.log(checked);
    await axios
      .get(
        `https://epstool.com/api/rondascooper/avanzado/${checked[0]}/${desde}/${hasta}`,
        // `http://localhost:5005/api/rondascooper/avanzado/${checked[0]}/${desde}/${hasta}`,
        {
          params: {
            equipos: checked,
          },
        }
      )
      .then((result) => {
        let dataToExcel = [];
        console.log(result.data.informesOrdenados);
        result.data.informesOrdenados.forEach((equipo) => {
          //Dentro de cada equipo
          let idxNombreMaquina = checked.indexOf(equipo.name);
          let nombreEquipo = nameChecked[idxNombreMaquina];
          let tipoEquipo = tipoChecked[idxNombreMaquina];
          equipo.nombre = nombreEquipo;
          equipo.tipo = tipoEquipo;
          let equipoToExcel = {
            id: equipo.name,
            nombre: nombreEquipo,
            variables: [],
          };
          equipo.informes.forEach((informe) => {
            //Dentro de cada informe
            let date = new Date(informe.fecha * 1000);
            let dia = `${date.getDate()}/${
              date.getMonth() + 1
            }/${date.getFullYear()}`;
            let hora = ` ${
              date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
            }:${
              date.getMinutes() < 10
                ? `0${date.getMinutes()}`
                : date.getMinutes()
            }`;
            informe.informacion.forEach((info) => {
              //Dentro de cada reporte especifico

              // console.log(equipoToExcel.variables);
              // let idxVarGraf = equipoToExcel.variables.findIndex(
              //   (e) => e.nombre === info.nombre
              // );
              // if (idxVarGraf < 0) {
              //   equipoToExcel.variables.push({
              //     nombre: info.nombre,
              //     valor: [{ dia: dia, dato: info.valor }],
              //   });
              // } else {
              //   equipoToExcel.variables[idxVarGraf].valor.push({
              //     dia: dia,
              //     dato: info.valor,
              //   });
              // }
              if (info.tipo === 2) {
                equipoToExcel.variables.push({
                  dia: dia,
                  hora: hora,
                  dato: Number(info.valor),
                  nombre: info.nombre,
                });
              } else {
                equipoToExcel.variables.push({
                  dia: dia,
                  hora: hora,
                  dato: info.valor,
                  nombre: info.nombre,
                });
              }
            });
          });
          dataToExcel.push(equipoToExcel);
        });
        setDataForExcel(dataToExcel);
        setFlagExcel(1);
        console.log(dataToExcel);
        setLoadingModal(false);
      })
      .catch((error) => {
        setTxtNotificacion("Se ha producido un error");
        showNotification();
      });
  };
  let acciones;
  let btnExcel;
  let hoy = new Date();
  if (flagExcel === 0) {
    btnExcel = (
      <Button
        disabled
        fullWidth
        color="success"
        onClick={handleGetExelEquipos}
        className={classes.marginRight}
      >
        Reporte en Excel
      </Button>
    );
  } else {
    btnExcel = (
      <ExcelFile
        filename={`Reporte ${hoy.getDate()}/${
          hoy.getMonth() + 1
        }/${hoy.getFullYear()} ${hoy.getHours()}:${hoy.getMinutes()}`}
        element={
          <Button color="success" fullWidth className={classes.marginRight}>
            Reporte en Excel
          </Button>
        }
      >
        {dataForExcel.map((equipo, index) => (
          <ExcelSheet
            key={equipo.id}
            data={equipo.variables}
            name={equipo.nombre}
          >
            <ExcelColumn label="Datos" value="dato" />
            <ExcelColumn label="Dia" value="dia" />
            <ExcelColumn label="Hora" value="hora" />
            <ExcelColumn label="Variable" value="nombre" />
          </ExcelSheet>
        ))}
      </ExcelFile>
    );
  }

  if (flagCarga === 0) {
    acciones = (
      <GridContainer>
        <GridItem xs={12}>
          <FormLabel className={classes.labelCustomHorizontal}>
            Búsqueda por fecha
          </FormLabel>
          <GridContainer>
            <GridItem xs={12} md={5}>
              <br />
              <FormControl fullWidth>
                <Datetime
                  onChange={handleFrom}
                  timeFormat={false}
                  inputProps={{ placeholder: "Desde" }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} md={5}>
              <br />

              <FormControl fullWidth>
                <Datetime
                  onChange={handleTo}
                  timeFormat={false}
                  inputProps={{ placeholder: "Hasta" }}
                />
              </FormControl>
            </GridItem>
            {/* <GridItem xs={12} md={2}>
              <br />

              {!desde || !hasta ? (
                <Button
                  disabled
                  fullWidth
                  color="success"
                  onClick={""}
                  className={classes.marginRight}
                >
                  Graficar
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="success"
                  onClick={handleGetAnalisis}
                  className={classes.marginRight}
                >
                  Graficar
                </Button>
              )}
            </GridItem> */}
            <br />
          </GridContainer>
          <GridContainer>
            <GridItem xs={4}>
              {!desde || !hasta ? (
                <Button
                  disabled
                  fullWidth
                  color="success"
                  onClick={handleGetPDFEquipos}
                  className={classes.marginRight}
                >
                  Reporte en PDF
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="success"
                  onClick={handleGetPDFEquipos}
                  className={classes.marginRight}
                >
                  Reporte en PDF
                </Button>
              )}
            </GridItem>
            <GridItem xs={4}>{btnExcel}</GridItem>
            <GridItem xs={4}>
              <Button
                fullWidth
                color="success"
                onClick={handleGetExelEquipos}
                className={classes.marginRight}
              >
                Cargar Datos
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  } else {
    acciones = (
      <GridContainer>
        <GridItem>
          <h4>Archivos Cargados con éxito</h4>
          <Button
            fullWidth
            color="success"
            onClick={() => setFlagCarga(0)}
            className={classes.marginRight}
          >
            {" "}
            Regresar
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  let graficos;

  if (flagCarga === 0) {
    graficos = (
      <GridContainer>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Seleccione los equipos a analizar:
                </FormLabel>
              </GridItem>
            </GridContainer>
            {dataMaqs[0]
              ? dataMaqs.map((element) => (
                  <GridItem xs={12} sm={12} key={element._id}>
                    <Accordion
                      active={1}
                      collapses={[
                        {
                          title: `${element.nombre}`,
                          content: (
                            <GridContainer>
                              <GridItem>
                                {dataMaqs[0].equipo
                                  ? element.equipo.map((maquina) => (
                                      <FormControlLabel
                                        xs={12}
                                        key={maquina._id}
                                        control={
                                          <Checkbox
                                            tabIndex={-1}
                                            onClick={() =>
                                              handleToggle(
                                                `${maquina._id}`,
                                                `${maquina.codMaquina}`,
                                                `${element.nombre}`
                                              )
                                            }
                                            checkedIcon={
                                              <Check
                                                className={classes.checkedIcon}
                                              />
                                            }
                                            icon={
                                              <Check
                                                className={
                                                  classes.uncheckedIcon
                                                }
                                              />
                                            }
                                            classes={{
                                              checked: classes.checked,
                                              root: classes.checkRoot,
                                            }}
                                          />
                                        }
                                        classes={{
                                          label: classes.label,
                                          root: classes.labelRoot,
                                        }}
                                        label={`${maquina.codMaquina}`}
                                      />
                                    ))
                                  : ""}
                              </GridItem>
                            </GridContainer>
                          ),
                        },
                      ]}
                    />
                  </GridItem>
                ))
              : ""}
          </CardBody>
        </Card>
      </GridContainer>
    );
  } else {
    graficos = (
      <GridContainer>
        {dataOrdenada[0]
          ? dataOrdenada[0].valores.map((variable, index) => (
              <GridItem key={index} xs={6}>
                <Card>
                  <CardHeader>
                    <h4 className={classes.cardIconTitle}>
                      {dataOrdenada[0].nombre} - {variable.nombre}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div style={{ width: "100%", height: 400 }}>
                      <ResponsiveContainer>
                        <LineChart
                          data={variable.series}
                          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                        >
                          {/* <ReferenceLine
                            y={equipo.tipo.variables[index].maximo}
                            label="Max"
                            stroke="red"
                          />
                          <ReferenceLine
                            y={equipo.tipo.variables[index].minimo}
                            label="Min"
                            stroke="blue"
                          /> */}
                          {}
                          <Line
                            type="monotone"
                            dataKey="RAH 19"
                            stroke="#8884d8"
                            // label={<CustomizedLabel />}
                          />
                          <Line
                            type="monotone"
                            dataKey="RAH 21"
                            stroke="#F84828"
                            // label={<CustomizedLabel />}
                          />

                          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                          {/* <XAxis /> */}
                          <XAxis dataKey="fecha" axisLine={false} />
                          <YAxis
                            type="number"
                            domain={["dataMin - 20", "dataMax + 20"]}
                          />
                          <Legend />
                          {/* <YAxis /> */}
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            ))
          : "No se cargaron los datos"}
      </GridContainer>
    );
  }

  class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;

      return (
        <text
          x={x}
          y={y}
          dy={-4}
          fill={stroke}
          fontSize={12}
          textAnchor="middle"
        >
          {value}
        </text>
      );
    }
  }

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader color="success" text>
            <CardText color="success">
              <h4 className={classes.cardTitle}>Configuración Reporte</h4>
            </CardText>
          </CardHeader>
          <CardBody>{acciones}</CardBody>
        </Card>
      </GridContainer>
      {graficos}
      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={loadingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLoadingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          {/* <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setLoadingModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button> */}
          <h4 className={classes.modalTitle}>
            Reportes de información avanzados
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12}>
              Cargando y ordenando la informacion solicitada...
            </GridItem>
            <br />
            <br />
            <br />
            <GridItem xs={3}></GridItem>
            <GridItem xs={6}>
              <CircularProgress />
            </GridItem>
            <GridItem xs={3}></GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Snackbar
        place="tc"
        color="danger"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
