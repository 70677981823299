import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";

import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Dashboard(props) {
  const classes = useStyles();
  const [failedModal, setFailedModal] = React.useState(false);
  const handleScan = (data) => {
    if (data) {
      if (
        data.substring(0, 27) === "https://cvrondas.dms.tools/" ||
        data.substring(0, 25) === "https://dms.tools/"
      ) {
        window.location.href = data;
      } else {
        setFailedModal(true);
      }
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <QueryBuilderIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>QR Scanner</h4>
          </CardHeader>
          <CardBody>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/* Modal failed */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={failedModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setFailedModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFailedModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Código inválido</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>El código escaneado no pertene al sistema de aplicaciones DMS</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setFailedModal(false)}
            block
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}
