import CrearMaquina from "views/Maquina/crearMaquina.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import RondasConfig from "views/Rondas/rondasConfig.js";
import Ronda from "views/Rondas/ronda.js";
import RondaDetails from "views/Rondas/rondaDetails.js";
import Maquina from "views/Maquina/maquina.js";
import ListaMaquinas from "views/Maquina/listaMaquinas.js";
import ExternalEquipo from "views/QR/externalEquipo";
import QrEquipo from "views/QR/qrEquipo.js";
import QrScanner from "views/QR/qrScanner.js";
import TipoMaquina from "views/Maquina/TipoMaquina.js";
import InformeForm from "views/Informes/informeForm.js";
import LoginPage from "views/Pages/LoginPage.js";
import ListaRondas from "views/Rondas/listaRondas.js";
import Informe from "views/Informes/informe.js";
import CambiarPass from "views/User/cambiarPass.js";
import BMS from "views/Apps/Apps";
import Apps from "@material-ui/icons/Apps";
import AnalisisConfig from "views/Analisis/analisisConfig.js";
import GraficosComparativos from "views/Analisis/graficosComparativos.js";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EditarMaq from "views/Maquina/editarMaquina.js";
import IngresarInforme from "views/Informes/ingresarInforme.js";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import ApartmentIcon from "@material-ui/icons/Apartment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Graficos from "views/Maquina/graficos";
import TimelineIcon from "@material-ui/icons/Timeline";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import FolderIcon from "@material-ui/icons/Folder";
import TablePassdown from "./views/Passdown/TablePassdown";
import CrearInforme from "views/Passdown/formulario/CrearInforme";
import Detail from "views/Passdown/detail/Detail";
import EditarInforme from "views/Passdown/formulario/EditarInforme";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/listaequipos",
    name: "Equipos",
    rtlName: "لوحة القيادة",
    icon: VideoLabelIcon,
    component: ListaMaquinas,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/cambiar_pass",
    name: "Cambiar contraseña",
    rtlName: "لوحة القيادة",
    icon: VideoLabelIcon,
    component: CambiarPass,
    layout: "/admin",
  },
  {
    path: "/listarondas",
    name: "Rondas",
    rtlName: "لوحة القيادة",
    icon: ApartmentIcon,
    component: ListaRondas,
    layout: "/admin",
  },
  {
    path: "/graficosAvanzados/",
    name: "Análisis",
    icon: TimelineIcon,
    mini: "AN",
    component: GraficosComparativos,
    layout: "/admin",
  },
  {
    path: "/analisis",
    name: "Reportes",
    mini: "AN",
    icon: ListAltIcon,
    component: AnalisisConfig,
    layout: "/admin",
  },
  {
    // invisible: true,
    path: "/qrscanner",
    name: "QR Scanner",
    rtlName: "لوحة القيادة",
    icon: VideoLabelIcon,
    component: QrScanner,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/qrcode/equipo/:_id",
    name: "Qr Equipo",
    mini: "RD",
    component: QrEquipo,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/security/:_id/:nfctag",
    name: "Equipo externo",
    mini: "RD",
    component: ExternalEquipo,
    layout: "/admin",
  },
  {
    path: "/rondaconfig",
    name: "Crear Ronda",
    mini: "CR",
    icon: QueryBuilderIcon,
    component: RondasConfig,
    layout: "/admin",
  },
  {
    collapse: true,
    path: "/passdown",
    name: "Passdown",
    mini: "PA",
    icon: FolderIcon,
    views: [
      {
        path: "/passdown-informes/2",
        name: "Informes",
        rtlName: "هعذاتسجيل الدخول",
        mini: "I",
        rtlMini: "هعذا",
        icon: AssignmentIcon,
        component: TablePassdown,
        layout: "/admin",
      },
      {
        path: "/passdown-informes/1",
        name: "Borradores",
        rtlName: "هعذاتسجيل الدخول",
        mini: "I",
        rtlMini: "هعذا",
        icon: AssignmentIndIcon,
        component: TablePassdown,
        layout: "/admin",
      },
      {
        path: "/passdown-create",
        name: "Crear informe",
        rtlName: "هعذاتسجيل الدخول",
        mini: "C",
        rtlMini: "هعذا",
        icon: AddIcon,
        component: CrearInforme,
        layout: "/admin",
      },
    ],
  },
  {
    invisible: true,
    path: "/passdown-edit/:id",
    name: "Editar informe",
    mini: "C",
    rtlMini: "هعذا",
    component: EditarInforme,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/informe-detail/:id",
    name: "Detalle",
    mini: "IN",
    component: Detail,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/informes/:_id",
    name: "Informe",
    mini: "IN",
    component: Informe,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/ronda/:_id",
    name: "Ronda",
    mini: "RD",
    component: Ronda,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/rondadetalles/:_id",
    name: "RondaDetails",
    mini: "RD",
    component: RondaDetails,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/maquina/:_id",
    name: "Equipo",
    mini: "EQ",
    component: Maquina,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/editarmaquina/:_id",
    name: "Equipo",
    mini: "EQ",
    component: EditarMaq,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/ingresarinforme/:_id",
    name: "Equipo",
    mini: "EQ",
    component: IngresarInforme,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/graficos/:_id",
    name: "Graficos",
    mini: "GR",
    component: Graficos,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/informe/:idmaq/:idronda/:idform/:idnfc",
    name: "Informe",
    mini: "IN",
    component: InformeForm,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/nuevotipo",
    name: "Tipo Maquina",
    mini: "TM",
    component: TipoMaquina,
    layout: "/admin",
  },
  {
    invisible: true,
    path: "/crearmaquina",
    name: "Crear Maquina",
    mini: "CM",
    component: CrearMaquina,
    layout: "/admin",
  },
  {
    collapse: true,
    invisible: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
    ],
  },
  // {
  //   collapse: true,
  //   // invisible: true,
  //   name: "Passdown",
  //   rtlName: "صفحات",
  //   icon: Image,
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/passdown-informes",
  //       name: "Informes",
  //       rtlName: "هعذاتسجيل الدخول",
  //       mini: "I",
  //       rtlMini: "هعذا",
  //       // component: LoginPage,
  //       layout: "/auth",
  //     },
  //     // {
  //     //   path: "/passdown-create",
  //     //   name: "Crear Informe",
  //     //   rtlName: "هعذاتسجيل الدخول",
  //     //   mini: "C",
  //     //   rtlMini: "هعذا",
  //     //   // component: LoginPage,
  //     //   layout: "/auth",
  //     // },
  //   ],
  // },
];
export default dashRoutes;
