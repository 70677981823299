import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

import Dvr from "@material-ui/icons/Dvr";
import Assessment from "@material-ui/icons/Assessment";

const useStyles = makeStyles(styles);

export default function Rondas(props) {
  const classes = useStyles();
  const idRonda = props.match.params._id;
  const [ronda, setRonda] = React.useState();
  const [equipos, setEquipos] = React.useState({});

  // CARGA DE LA RONDA
  const fetchData = async () => {
    // console.log(idRonda);

    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/ronda/${idRonda}`
      `https://epstool.com/api/rondascooper/ronda/${idRonda}`
    );
    setRonda(result.data.ronda);
    setEquipos(result.data.ronda.informesDetallados);
    // console.log(result.data.ronda);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  let tbEquipos;

  Object.keys(equipos).length >= 1
    ? (tbEquipos = equipos.map((equipo) => {
        // console.log(equipo.estadoMultiple.includes(1105));

        return {
          nombre: equipo.maquina,
          // zona: equipo.ubicacion.nombre,
          zona: equipo.ubicacion,
          estado: equipo.estado === 0 ? "Pendiente" : "Completado",
          actions: (
            <div className="actions-right">
              {equipo.estado === 0 ? (
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    props.history.push(
                      `../informe/${equipo.uuid}/${idRonda}/${equipo.tipoMaq}/xdldggpw4931evwy`
                    );
                  }}
                  color="warning"
                  className="edit"
                >
                  <Assessment />
                </Button>
              ) : (
                <Button justIcon round simple color="success" className="edit">
                  <Assessment />
                </Button>
              )}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  props.history.push(`../maquina/${equipo.uuid}`);
                }}
                color="warning"
                className="edit"
              >
                <Dvr />
              </Button>{" "}
            </div>
          ),
        };
      }))
    : (tbEquipos = []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <QueryBuilderIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {ronda
                ? `${ronda.configuracion.nombre} ${ronda.codigo} - Estado: ${ronda.estado}`
                : ""}
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[
                {
                  Header: "Nombre",
                  accessor: "nombre",
                },
                {
                  Header: "Zona",
                  accessor: "zona",
                },
                {
                  Header: "Estado",
                  accessor: "estado",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                },
              ]}
              data={tbEquipos}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
