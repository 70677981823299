import React from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Danger from "components/Typography/Danger";

// import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function TipoMaquina() {
  const [form, setForm] = React.useState([
    {
      id: 0,
      name: "",
      um: "",
      min: "",
      max: "",
      tipo: "",
      obligatorio: "",
      graficar: "",
    },
  ]);
  const [tipoName, setTipoName] = React.useState("");
  const [errTipoName, setErrTipoName] = React.useState("");
  const [errors, setErrors] = React.useState([
    { id: 0, name: "", tipo: "", obligatorio: "" },
  ]);

  const handleSimple = (event, id) => {
    event.preventDefault();
    let txt = event.target.value;
    form[id].tipo = txt;
    setForm([...form]);

    // arrTipo[id] = event.target.value;
    // setArrTipo([...arrTipo]);
  };

  const verificar = (inputs) => {
    let errorMessage = [{}];
    let errors = 0;
    if (!tipoName) {
      setErrTipoName("Debe escribir nombre del tipo");
    } else {
      setErrTipoName("");
    }

    inputs.forEach((element) => {
      errorMessage = [...errorMessage, {}];
      if (!element.name) {
        errorMessage[element.id].name =
          "Debe escribir el nombre de esta variable";
        errors = errors + 1;
      } else {
        errorMessage[element.id].name = "";
      }
      if (!element.tipo) {
        errorMessage[element.id].tipo =
          "Debe escribir el tipo de esta variable";
        errors = errors + 1;
      } else {
        errorMessage[element.id].tipo = "";
      }
      if (!element.obligatorio) {
        errorMessage[element.id].obligatorio = "Debe asignar este campo";
        errors = errors + 1;
      } else {
        errorMessage[element.id].obligatorio = "";
      }
    });
    setErrors([...errorMessage]);
    return errors;
  };

  const handleTipoSubmit = async (event) => {
    event.preventDefault();
    let flag = verificar(form);
    if (flag === 0) {
      const tipoMaquinaToSave = {
        nombre: tipoName,
        variables: form,
      };
      await axios
        // .post("http://localhost:5050/api/rondascooper/nuevotipo", tipoMaquinaToSave)
        .post("https://epstool.com/api/rondascooper/nuevotipo", tipoMaquinaToSave)
        .then((res) => {
          setTipoName("");
          setForm([
            {
              id: 0,
              name: "",
              um: "",
              min: "",
              max: "",
              tipo: "",
              obligatorio: "",
              graficar: "",
            },
          ]);
          setErrors([{ id: 0, name: "", tipo: "", obligatorio: "" }]);
          // showNotification();
        })
        .catch((error) => {
          setErrors(console.log(error));
        });
    }
  };

  const handleAddInput = async (event) => {
    event.preventDefault();
    setForm([
      ...form,
      {
        id: form.length,
        name: "",
        um: "",
        min: "",
        max: "",
        tipo: "",
        obligatorio: "",
        graficar: "",
      },
    ]);
    setErrors([
      ...errors,
      { id: errors.length, name: "", tipo: "", obligatorio: "", graficar: "" },
    ]);
  };

  const handleCustomInput = async (event, id, inpt) => {
    event.preventDefault();
    let txt = event.target.value;
    switch (inpt) {
      case 1:
        form[id].name = txt;
        break;
      case 2:
        form[id].um = txt;
        break;
      case 3:
        parseFloat(txt);
        form[id].min = txt;
        break;
      case 4:
        parseFloat(txt);
        form[id].max = txt;
        break;
      case 5:
        form[id].obligatorio = txt;
        break;
      case 6:
        form[id].graficar = txt;
        break;
      default:
        break;
    }
    setForm([...form]);
  };

  const classes = useStyles();

  let formulario;

  formulario = form.map((inputs) => (
    <GridContainer key={inputs.id.toString()}>
      {/* Nombre */}
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>
          Variable {inputs.id + 1}*:
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          multiline
          labelText="Ej: Temperatura"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleCustomInput(e, inputs.id, 1),
            type: "text",
            value: form[inputs.id].name,
          }}
        />
        <Danger>
          {errors[inputs.id].name === undefined ? null : (
            <div className="invalid-feedback">{errors[inputs.id].name}</div>
          )}
        </Danger>
      </GridItem>
      {/* Tipo */}
      <GridItem xs={12} sm={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Tipo de variable*
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={form[inputs.id].tipo}
            onChange={(e) => handleSimple(e, inputs.id)}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Tipo de Variable
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="1"
            >
              Texto
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="2"
            >
              Numero
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="3"
            >
              Booleano
            </MenuItem>
          </Select>
        </FormControl>
        <Danger>
          {errors[inputs.id].tipo === undefined ? null : (
            <div className="invalid-feedback">{errors[inputs.id].tipo}</div>
          )}
        </Danger>
      </GridItem>

      {/* Unidad */}
      <GridItem xs={12} sm={2}>
        <FormLabel className={classes.labelCustomHorizontal}>
          Unidad de medida:
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={4}>
        <CustomInput
          multiline
          labelText="Ej: Farenheit"
          id={inputs.id.toString()}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            onChange: (e) => handleCustomInput(e, inputs.id, 2),
            type: "text",
            value: form[inputs.id].um,
          }}
        />
      </GridItem>

      {/* Obligatorio */}
      <GridItem xs={12} sm={3}>
        <FormLabel
          className={
            classes.labelCustomHorizontal +
            " " +
            classes.labelHorizontalRadioCheckbox
          }
        >
          ¿Esta variable es obligatoria?*
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <div
          className={
            classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
          }
        >
          <FormControlLabel
            control={
              <Radio
                checked={form[inputs.id].obligatorio === "a"}
                onChange={(e) => handleCustomInput(e, inputs.id, 5)}
                value="a"
                name="radio button demo"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="SI"
          />

          <FormControlLabel
            control={
              <Radio
                checked={form[inputs.id].obligatorio === "b"}
                onChange={(e) => handleCustomInput(e, inputs.id, 5)}
                value="b"
                name="radio button demo"
                aria-label="B"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot,
            }}
            label="NO"
          />
        </div>
        <Danger>
          {errors[inputs.id].obligatorio === undefined ? null : (
            <div className="invalid-feedback">
              {errors[inputs.id].obligatorio}
            </div>
          )}
        </Danger>
      </GridItem>

      {/* Validacion */}
      {form[inputs.id].tipo === "2" ? (
        <GridItem xs={12} sm={2}>
          <FormLabel className={classes.labelCustomHorizontal}>
            Validaciones
          </FormLabel>
        </GridItem>
      ) : null}
      {form[inputs.id].tipo === "2" ? (
        <GridItem xs={12} sm={1}>
          <FormLabel className={classes.labelCustomHorizontal}>
            Minimo
          </FormLabel>
        </GridItem>
      ) : null}
      {form[inputs.id].tipo === "2" ? (
        <GridItem xs={12} sm={4}>
          <CustomInput
            multiline
            labelText="Ej: 10"
            id={inputs.id.toString()}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (e) => handleCustomInput(e, inputs.id, 3),
              type: "number",
              value: form[inputs.id].min,
            }}
          />
        </GridItem>
      ) : null}
      {form[inputs.id].tipo === "2" ? (
        <GridItem xs={12} sm={1}>
          <FormLabel className={classes.labelCustomHorizontal}>
            Maximo
          </FormLabel>
        </GridItem>
      ) : null}
      {form[inputs.id].tipo === "2" ? (
        <GridItem xs={12} sm={4}>
          <CustomInput
            multiline
            labelText="Ej: 30"
            id={inputs.id.toString()}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onChange: (e) => handleCustomInput(e, inputs.id, 4),
              type: "number",
              value: form[inputs.id].max,
            }}
          />
        </GridItem>
      ) : null}
      {/* Graficar */}
      {form[inputs.id].tipo === "2" ? (
        <GridItem xs={12} sm={4}>
          <FormLabel
            className={
              classes.labelCustomHorizontal +
              " " +
              classes.labelHorizontalRadioCheckbox
            }
          >
            Desea gráficar la variable:
          </FormLabel>
        </GridItem>
      ) : null}
      {form[inputs.id].tipo === "2" ? (
        <GridItem xs={12} sm={6}>
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={form[inputs.id].graficar === "a"}
                  onChange={(e) => handleCustomInput(e, inputs.id, 6)}
                  value="a"
                  name="radio button demo"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="SI"
            />

            <FormControlLabel
              control={
                <Radio
                  checked={form[inputs.id].graficar === "b"}
                  onChange={(e) => handleCustomInput(e, inputs.id, 6)}
                  value="b"
                  name="radio button demo"
                  aria-label="B"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="NO"
            />
          </div>
        </GridItem>
      ) : null}
    </GridContainer>
  ));

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success" text>
            <CardText color="success">
              <h4 className={classes.cardTitle}>Crear nuevo tipo</h4>
            </CardText>
          </CardHeader>
          <CardBody>
            {/* Nombre Tipo */}
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <FormLabel className={classes.labelCustomHorizontal}>
                  Nombre del tipo:
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10}>
                <CustomInput
                  labelText="Ej: Maquinas MAH"
                  id="tipoName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      setTipoName(event.target.value);
                    },
                    type: "text",
                    value: tipoName,
                  }}
                />
                <Danger>
                  {errTipoName === undefined ? null : (
                    <div className="invalid-feedback">{errTipoName}</div>
                  )}
                </Danger>
              </GridItem>
            </GridContainer>
            <form>{formulario}</form>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <Button fullWidth color="success" onClick={handleAddInput}>
                  Añadir campo
                </Button>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Button fullWidth color="success" onClick={handleTipoSubmit}>
                  Crear tipo
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
