import React, { useState, useEffect, PureComponent } from "react";
import axios from "axios";
// excel
import ReactExport from "react-data-export";
// react plugin for creating charts
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
} from "recharts";
import ChartistGraph from "react-chartist";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import Place from "@material-ui/icons/Place";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter";
import Close from "@material-ui/icons/Close";
import CustomInput from "components/CustomInput/CustomInput.js";
import jwt_decode from "jwt-decode";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Dvr from "@material-ui/icons/Dvr";
import ReactFilestack from "filestack-react";
import Success from "components/Typography/Success.js";
import DescriptionIcon from "@material-ui/icons/Description";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "components/Snackbar/Snackbar.js";
import Danger from "components/Typography/Danger";

import { colouredLineChart } from "variables/charts.js";

const useStyles = makeStyles(styles);

//Excel req
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Graficos(props) {
  const classes = useStyles();

  const idEquipo = props.match.params._id;
  const [equipo, setEquipo] = React.useState();
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [tc, setTC] = React.useState(false);
  const [nombreGraf, setNombreGraf] = React.useState([]);
  const [dataGraficos, setDataGraficos] = useState([
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
    {
      labels: [],
      series: [],
      max: 0,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [savingModal, setSavingModal] = React.useState(false);

  const [desde, setDesde] = useState();
  const [hasta, setHasta] = useState();

  // CARGA DEL EQUIPO
  const fetchData = async () => {
    // setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      // `http://localhost:5005/api/rondascooper/maquina/${idEquipo}`
      `https://epstool.com/api/rondascooper/maquina/${idEquipo}`
    );
    setEquipo(result.data);
    fetchGraph(result.data.informes);
  };

  const fetchGraph = async (informes) => {
    // console.log(informes);

    let arrData = [];
    let arrLabel = [];
    let arrNombreVar = [];
    // [{name:"10/12",valor:500},{},{}]
    informes.forEach((informe, i) => {
      informe.informacion.forEach((element, index) => {
        if (element.tipo === 2) {
          if (i === 0) {
            arrData[index] = [];
            arrLabel[index] = [];
            arrNombreVar[index] = [];
            arrNombreVar[index] = element.nombre;
          }
          let date = new Date(informe.fecha * 1000);
          arrData[index].unshift({
            name: `${date.getDate()}/${date.getMonth() + 1}`,
            valor: parseInt(element.valor),
          });
          arrLabel[index].unshift(parseInt(element.valor));
        }
      });
    });

    // console.log("arrData");
    // console.log(arrData);
    // console.log(arrLabel);
    setNombreGraf(arrNombreVar);
    arrData.forEach((element, index) => {
      let data = {
        labels: [],
        series: [],
        max: 0,
      };
      data.labels = arrLabel[index];
      data.series = arrData[index];
      data.max = Math.max(...arrLabel[index]);

      dataGraficos[index] = data;
      setDataGraficos([...dataGraficos]);
    });
  };
  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  const showNotification = () => {
    setTC(true);
    setTimeout(function () {
      setTC(false);
    }, 6000);
  };

  const handleFrom = (e) => {
    setDesde(e.unix());
  };
  const handleTo = (e) => {
    setHasta(e.unix());
  };

  const handleDates = async (event) => {
    setSavingModal(true);
    event.preventDefault();
    const resultDates = await axios.get(
      // `http://localhost:5005/api/rondascooper/date/${equipo._id}/${desde}/${hasta}`
      `https://epstool.com/api/rondascooper/date/${equipo._id}/${desde}/${hasta}`
    );
    console.log('desde',desde);
    console.log('hasta', hasta);
    console.log('equipo._id', equipo._id);

    console.log(resultDates);
    let informesTipoPrincipal = [];
    let informesTipoExtra = [];
    resultDates.data.forEach((element) => {
      if (element.tipoMaq === equipo.tipo._id) {
        informesTipoPrincipal.push(element);
      } else {
        informesTipoExtra.push(element);
      }
    });
    fetchGraph(informesTipoPrincipal);
    setSavingModal(false);
  };

  let buttonExcel;

  if (!dataGraficos[0]) {
    buttonExcel = (
      <Button
        color="success"
        fullWidth
        disabled
        className={classes.marginRight}
      >
        Exportar Excel
      </Button>
    );
  } else {
    let dataForExcel = [];
    console.log(dataGraficos);
    let counter = 0;

    for (let index = 0; index < dataGraficos.length; index++) {
      const element = dataGraficos[index];

      let organizador = [];
      if (element.series[0]) {
        // console.log(.valor);
        element.series.forEach((ele) => {
          let namedata = `datos${counter}`;
          let namefecha = `fecha${counter}`;
          let obj = {};
          obj[namedata] = ele.valor;
          obj[namefecha] = ele.name;
          organizador.push(obj);
        });

        dataForExcel.push(organizador);
        counter = counter + 1;
      }
    }
    console.log(dataForExcel);

    const names = nombreGraf.filter(String);

    let hoy = new Date();
    buttonExcel = (
      <ExcelFile
        filename={`Reporte ${hoy.getDate()}/${
          hoy.getMonth() + 1
        }/${hoy.getFullYear()} ${hoy.getHours()}:${hoy.getMinutes()}`}
        element={
          <Button color="success" fullWidth className={classes.marginRight}>
            Exportar Excel
          </Button>
        }
      >
        {dataForExcel.map((e, index) => (
          <ExcelSheet data={dataForExcel[index]} name={names[index]}>
            <ExcelColumn label="Datos" value={`datos${index}`} />
            <ExcelColumn label="Fecha" value={`fecha${index}`} />
          </ExcelSheet>
        ))}
      </ExcelFile>
    );
  }

  let panelFechas;
  if (!dataGraficos[0]) {
    panelFechas = null;
  } else {
    panelFechas = (
      <GridContainer>
        <GridItem xs={12}>
          <InputLabel className={classes.label}>Búsqueda por fecha</InputLabel>
          <br />
          <GridContainer>
            <GridItem xs={12} md={5}>
              <FormControl fullWidth>
                <Datetime
                  onChange={handleFrom}
                  timeFormat={false}
                  inputProps={{ placeholder: "Desde" }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} md={5}>
              <FormControl fullWidth>
                <Datetime
                  onChange={handleTo}
                  timeFormat={false}
                  inputProps={{ placeholder: "Hasta" }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} md={2}>
              {!desde || !hasta ? (
                <Button
                  disabled
                  fullWidth
                  color="success"
                  onClick={handleDates}
                  className={classes.marginRight}
                >
                  Calcular
                </Button>
              ) : (
                <Button
                  fullWidth
                  color="success"
                  onClick={handleDates}
                  className={classes.marginRight}
                >
                  Calcular
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }

  class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;

      return (
        <text
          x={x}
          y={y}
          dy={-4}
          fill={stroke}
          fontSize={12}
          textAnchor="middle"
        >
          {value}
        </text>
      );
    }
  }

  return (
    <div>
      {/* Panel de busqueda */}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {equipo ? `Graficos equipo ${equipo.codMaquina}` : ""}
              </h4>
            </CardHeader>
            <CardBody>
              {panelFechas}
              {buttonExcel}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      {/* Graficos */}
      <GridContainer>
        {dataGraficos[0]
          ? dataGraficos.map((element, index) =>
              element.labels.length > 0 ? (
                <GridItem xs={12} sm={12} md={12}>
                  <Card key={index}>
                    <CardHeader color="success" icon>
                      <CardIcon color="success">
                        <Dvr />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {/* Coloured Line Chart <small>- Rounded</small> */}
                        {/* {nameGraphOne} */}
                        {nombreGraf[index]}
                        {equipo.tipo.variables[index].unidadMedida
                          ? `- Unidad de medida: ${equipo.tipo.variables[index].unidadMedida}`
                          : null}
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <div style={{ width: "100%", height: 400 }}>
                        <ResponsiveContainer>
                          <LineChart
                            data={element.series}
                            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                          >
                            <ReferenceLine
                              y={equipo.variables[index]?.maximo}
                              label="Max"
                              stroke="red"
                            />
                            <ReferenceLine
                              y={equipo.variables[index]?.minimo}
                              label="Min"
                              stroke="blue"
                            />
                            <Line
                              type="monotone"
                              dataKey="valor"
                              stroke="#8884d8"
                              label={<CustomizedLabel />}
                            />
                            <CartesianGrid
                              stroke="#ccc"
                              strokeDasharray="5 5"
                            />
                            <XAxis dataKey="name" axisLine={false} />
                            <YAxis
                              type="number"
                              domain={["dataMin - 20", "dataMax + 20"]}
                            />
                            <Legend />
                            {/* <YAxis /> */}
                            <Tooltip />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                      {/* <ChartistGraph
                        data={element}
                        type="Line"
                        options={colouredLineChart.options}
                        listener={colouredLineChart.animation}
                      /> */}
                    </CardBody>
                  </Card>
                </GridItem>
              ) : null
            )
          : null}
      </GridContainer>

      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={savingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSavingModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <p>Obteniendo informes, espere un momento...</p>
        </DialogContent>
      </Dialog>
      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}
