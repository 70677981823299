import React, { useState } from "react";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { arrOfInformes } from "_mock/_passdown";
import jwt_decode from "jwt-decode";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ReactFilestack from "filestack-react";

import {
  Box,
  Button,
  CardActions,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { turnos } from "_mock/_passdown";
import usePassdown from "../hooks/usePassdown";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Danger from "components/Typography/Danger";
import { Close } from "@material-ui/icons";
import Accordion from "components/Accordion/Accordion";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import BlockIcon from "@material-ui/icons/Block";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CrearInforme(props) {
  const API_KEY = "AWevYlMNkS7KJFbiRivemz";
  const { orderDataToSend, validationForm, stockManagement } = usePassdown();
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [alert, setAlert] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const hoy = new Date().toLocaleDateString();
  const user = jwt_decode(localStorage.jwtToken);
  const [urlFile, setUrlFile] = useState([]);
  const [urlFileError, setUrlFileError] = useState();
  const [inputName, setInputName] = useState("");
  const options = {
    maxFiles: 1,
    fromSources: ["local_file_system", "audio"],
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeFile = () => {
    if (!!form[inputName]) {
      setForm({
        ...form,
        [inputName]: [...form[inputName], ...urlFile],
      });
    } else {
      setForm({
        ...form,
        [inputName]: urlFile,
      });
    }
    setOpenFiles(false);
    setUrlFile([]);
  };

  const handleOpenModal = (id) => {
    setOpenFiles(true);
    setInputName(id);
  };

  const handleCloseModal = () => {
    setOpenFiles(false);
    setUrlFile([]);
  };

  const handleSubmit = async () => {
    const errores = validationForm(form);
    delete errores.estado;
    console.log(errores);
    if (Object.keys(errores).length > 0) {
      setError(errores);
    } else {
      const dataToSave = orderDataToSend(form, user?.name, 2);
      // await axios.post(`http://localhost:5050/api/passdown`, dataToSave);
      await axios.post(`https://epstool.com/api/passdown`, dataToSave);
      handleConfirm();
      setForm({});
      setError({});
    }
  };

  const handleBorrador = async () => {
    const errores = {};
    const verification = stockManagement(1, user?.name);
    if (!form.turno || form.turno === "") {
      errores.turno = "Este campo es obligatorio";
    } else if (verification === true) {
      errores.alreadyExists =
        "!Lo sentimos¡. Tu informe no puede ser guardado porque ya existe un informe en borradores con tu nombre.";
    }

    if (Object.keys(errores).length > 0) {
      setError(errores);
    } else {
      const dataToSave = orderDataToSend(form, user?.name, 1);
      // await axios.post(`http://localhost:5050/api/passdown`, dataToSave);
      await axios.post(`https://epstool.com/api/passdown`, dataToSave);
      props.history.push("/admin/passdown-informes/1");
    }
  };

  const handleConfirm = () => {
    setAlert(true);
  };
  const handleCancel = () => {
    setAlert(false);
  };

  const handleDeleteFile = (item) => {
    const filteredData = urlFile?.filter((el) => el.url !== item);
    setUrlFile(filteredData);
  };

  const handleDeleteSaveFile = async (item, x) => {
    const input = form[item];
    const filteredData = input?.filter((el) => el.url !== x);
    setForm({ ...form, [item]: filteredData });
  };

  return (
    <Card>
      <CardHeader icon>
        <CardIcon color="success">
          <AssignmentIcon />
        </CardIcon>
        <h4 style={{ color: "#000" }}>Crear informe</h4>
      </CardHeader>
      <CardBody>
        {alert === true && (
          <SweetAlert
            success
            title="Informe guardado con exito"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            timeout={1500}
            customButtons={
              <React.Fragment>
                <Button onClick={handleCancel} variant="outlined">
                  cerrar
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#4caf50",
                    color: "#fff",
                    margin: 10,
                  }}
                  onClick={() =>
                    props.history.push("/admin/passdown-informes/2")
                  }
                >
                  Ir a tabla de informes
                </Button>
              </React.Fragment>
            }
          />
        )}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel>Encargado</FormLabel>
            <TextField
              inputProps={{ readOnly: true }}
              value={user?.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel>Fecha</FormLabel>
            <TextField inputProps={{ readOnly: true }} value={hoy} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel>Turno</FormLabel>
            <Select
              fullWidth
              value={form["turno"] ?? ""}
              name="turno"
              onChange={(e) => handleChange(e)}
              error={!!error["turno"] && !form["turno"]}
            >
              {turnos?.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.nombre}
                </MenuItem>
              ))}
            </Select>
            {!!error["turno"] && !form["turno"] && (
              <FormHelperText error>{error["turno"]}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <FormLabel>Pendientes</FormLabel>
            <TextField
              name="pendientes"
              onChange={(e) => handleChange(e)}
              variant="outlined"
              rows={5}
              value={form["pendientes"] ?? ""}
              multiline
              fullWidth
              error={!!error["pendientes"] && !form["pendientes"]}
            />
            {!!error["pendientes"] && !form["pendientes"] && (
              <FormHelperText error>{error["pendientes"]}</FormHelperText>
            )}
          </Grid>
          {arrOfInformes?.map((el) => (
            <React.Fragment key={el.id}>
              <Grid item xs={12}>
                <h5 style={{ fontWeight: "bold" }}>{el.title}</h5>
              </Grid>

              {el.sections?.map((item) => (
                <React.Fragment key={item.id}>
                  {item.type === "radio" && (
                    <Grid item xs={12} sm={6}>
                      <FormLabel>{item.label}</FormLabel>
                    </Grid>
                  )}
                  {item.type === "radio" && (
                    <React.Fragment>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            name={item.cod}
                            row
                            value={form[item.cod] ?? ""}
                            onChange={(e) => handleChange(e)}
                          >
                            <FormControlLabel
                              value="normal"
                              control={<Radio />}
                              label="Normal"
                            />
                            <FormControlLabel
                              value="evento"
                              control={<Radio />}
                              label="Evento"
                            />
                          </RadioGroup>
                          {!!error[item.cod] && !form[item.cod] && (
                            <FormHelperText error>
                              {error[item.cod]}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Tooltip title="Cargar imagen">
                          <IconButton
                            onClick={() => handleOpenModal(`${item.cod}Imagen`)}
                          >
                            <CloudUploadIcon />
                          </IconButton>
                        </Tooltip>
                        {form[`${item.cod}Imagen`]?.length > 0 && (
                          <Box width={120} marginLeft={{ sm: 3 }}>
                            <Accordion
                              collapses={[
                                {
                                  title: "imagenes",
                                  content: (
                                    <List>
                                      {form[`${item.cod}Imagen`]?.map(
                                        (image) => (
                                          <ListItem key={image.url}>
                                            <ListItemText
                                              primary={
                                                <a
                                                  href={image.url}
                                                  target="_blank"
                                                >
                                                  {image.fileName}
                                                </a>
                                              }
                                            />
                                            <ListItemIcon
                                              onClick={() =>
                                                handleDeleteSaveFile(
                                                  `${item.cod}Imagen`,
                                                  image.url
                                                )
                                              }
                                            >
                                              <Close
                                                style={{
                                                  color: "red",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </ListItemIcon>
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  ),
                                },
                              ]}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormLabel>Descripción</FormLabel>
                        <TextField
                          name={item.cod + "Descripcion"}
                          onChange={(e) => handleChange(e)}
                          value={form[item.cod + "Descripcion"] ?? ""}
                          fullWidth
                          multiline
                          error={
                            !!error[item.cod + "Descripcion"] &&
                            !form[item.cod + "Descripcion"]
                          }
                          onCopy={(e) => {
                            e.preventDefault();
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                          }}
                        />
                        {!!error[item.cod + "Descripcion"] &&
                          !form[item.cod + "Descripcion"] && (
                            <FormHelperText error>
                              {error[item.cod + "Descripcion"]}
                            </FormHelperText>
                          )}
                      </Grid>
                    </React.Fragment>
                  )}

                  {item.type === "text" && (
                    <Grid
                      item
                      xs={12}
                      sm={item.width}
                      style={{
                        marginTop: "3rem",
                        marginBottom: "3rem",
                      }}
                    >
                      <FormLabel style={{ color: "#000", marginRight: 20 }}>
                        {item.label}
                      </FormLabel>

                      <TextField
                        type="number"
                        name={item.cod}
                        onChange={(e) => handleChange(e)}
                        value={form[item.cod] ?? ""}
                        error={!!error[item.cod] && !form[item.cod]}
                        onCopy={(e) => {
                          e.preventDefault();
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                      />
                      {!!error[item.cod] && !form[item.cod] && (
                        <FormHelperText error>{error[item.cod]}</FormHelperText>
                      )}
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </CardBody>
      <CardActions>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={7}>
            {Object.keys(error).length > 0 ? (
              <>
                {error["turno"] && (
                  <Chip
                    style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                    size="large"
                    icon={<BorderColorIcon />}
                    label="Faltan campos por completar"
                    variant="outlined"
                    color="secondary"
                  />
                )}

                {error["alreadyExists"] && (
                  <Chip
                    style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                    size="large"
                    icon={<BlockIcon />}
                    label={error["alreadyExists"]}
                    variant="outlined"
                    color="secondary"
                  />
                )}
              </>
            ) : (
              <Typography variant="body2" color="error"></Typography>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#ff9800",
                color: "#fff",
                marginRight: 10,
                marginTop: 5,
                marginBottom: 5,
              }}
              size="large"
              onClick={handleBorrador}
            >
              Guardar en borradores
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#4caf50",
                color: "#fff",
                marginTop: 5,
                marginBottom: 5,
              }}
              size="large"
              onClick={handleSubmit}
            >
              Guardar y enviar
            </Button>
          </Grid>
        </Grid>
      </CardActions>

      <Dialog
        open={openFiles}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseModal}
      >
        <DialogTitle disableTypography>
          <h4>Agregar archivo</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Grid
            container
            style={{
              minWidth: 300,
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <ReactFilestack
              apikey={API_KEY}
              onSuccess={(result) =>
                setUrlFile([
                  ...urlFile,
                  {
                    fileName: result.filesUploaded[0].filename,
                    url: result.filesUploaded[0].url,
                  },
                ])
              }
              onError={(error) =>
                setUrlFileError(
                  "Se ha producido un error, porfavor intentarlo nuevamente"
                )
              }
              actionOptions={options}
              customRender={({ onPick }) => (
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={onPick}
                    style={{
                      backgroundColor: "#42a5f5",
                      color: "#fff",
                    }}
                  >
                    Cargar archivo
                  </Button>
                  {urlFile?.length > 0 ? (
                    <List
                      subheader={
                        <ListSubheader>Archivos Cargados</ListSubheader>
                      }
                    >
                      {urlFile.map((fileItem) => (
                        <ListItem key={fileItem.url}>
                          <ListItemText primary={fileItem.fileName} />
                          <ListItemIcon
                            onClick={() =>
                              handleDeleteFile(fileItem.url, urlFile)
                            }
                          >
                            <Close
                              style={{
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                  ) : null}
                  {urlFileError ? (
                    <Danger>
                      <p>{urlFileError}</p>
                    </Danger>
                  ) : null}
                </div>
              )}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="contained" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
            size="large"
            onClick={handleChangeFile}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
